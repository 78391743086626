import Colors from "../../../theme/colors/colors";

export default {
    container: {
        display: 'grid',
        gridTemplateColumns: '40% 10% 50%',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${Colors.silverSand}`,
        marginRight: 8,
        paddingBottom: 8,
        paddingTop: 8
    },
    username: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: Colors.davyGray,
        fontWeight: 500,
        paddingRight: 2
    },
    dot: {
        color: Colors.davyGray,
        fontWeight: 500
    },
    workplace: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: Colors.davyGray,
        fontWeight: 500
    }
};
