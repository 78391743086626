import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import {STRING} from "../../../constants/strings/strings";
import {
    getAfirmacionService,
} from "../../../data/service/delegadoService";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Button from "../../../components/units/Button/Button";
import questionTitleBg from "../../../assets/question-title-bg.png";

const QuestionTitle = () => {

    const {meetingType, roomCode, affirmationId, justificationNum} = useParams();

    const [isFullscreen, setIsFullscreen] = useState(false);

    const [slides, setSlides] = useState([]);

    const isVideo = window.location.href.includes('video');

    const navigate = useNavigate();

    const [fetching, setFetching] = useState(false);

    const [afirmacion, setAfirmacion] = useState([]);

    const handleBackButton = () => {
        console.log('clicked back button');
    }


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        setFetching(true);
        getAfirmacionService(affirmationId, roomCode)
            .then(r => {
                console.log(r);
                setAfirmacion(r)
                const justificaciones = meetingType === "visit" ? r.justificacion_visita : r.justificaciones;
                const images = [];
                justificaciones?.forEach((justificacion) => {
                    if (justificacion.path[0] === '/') images.push({url: justificacion.path})
                    else images.push({url: "/" + justificacion.path})
                })
                console.log(images)
                setSlides(images);
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    const handleNextButton = () => {
        if (meetingType === "meeting") {
            navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/results`);
        }
        else if (meetingType === "visit") {
            if (afirmacion.completed) {
                navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/visita`);
            } else {
                navigate(`/delegado/${meetingType}/${roomCode}/affirmationAnswer/${affirmationId}`);
            }
        }
    }


    return (
        <Body>
            <MainBackground enableBackButton backButtonPress={handleBackButton} roomCode={roomCode} title={afirmacion?.titulo_externo ?  afirmacion?.titulo_externo : "CARGANDO..."} loading={fetching}>
                <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{backgroundImage: `url(${questionTitleBg})`, backgroundSize: '100%', backgroundPosition: "center", borderRadius: 8, marginBottom: 32, transform: 'rotate(-2deg)'}}>
                    <h1 className="background__title-text" style={{fontSize: 38, textAlign: "center", padding: 32, color: 'white'}}>{afirmacion.pregunta}</h1>
                </div>
                </div>
                <div style={{display: "flex", justifyContent: "center", padding: 12}}>
                    <div style={{maxWidth: 200}}>
                        <Button title={STRING.NEXT} onClick={handleNextButton}/>
                    </div>
                </div>
            </MainBackground>
        </Body>
    )
};

export default QuestionTitle;
