import Colors from "../../../theme/colors/colors";

export default {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 100
    },
    logo: {
        height: 90
    },
    title: {
        fontFamily: "BebasNeue",
        fontSize: 28,
        color: Colors.white,
        margin: '12px 0px'
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 12,
        paddingRight: 50
    },

    errorTitle: {
        fontFamily: "BebasNeue",
        fontSize: 40,
        marginBottom: 20
    },
}
