import Colors from "../../../theme/colors/colors";

export default {
    container: {
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 120
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    logoImage: {
        width: '90%',
        maxWidth: 920
    },
    sloganImage: {
        width: '50%'
    },
    landingText: {
        fontFamily: "BebasNeue",
        fontSize: 32,
        color: Colors.white,
        marginBottom: 24
    }
};
