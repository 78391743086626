import React from "react";

import "./styles.css";

const SelectorCard = ({isActive = false, text, onCardClick = () => {}, isDefaultStatus = true}) => {
    return (
        <div
            className={`${isActive ? "card-active" : "card-disabled"}`}
            onClick={onCardClick}
        >
            <p className={`card-text ${!isActive && !isDefaultStatus ? "--text-disabled" : ""}`}>{text}</p>
        </div>
    )
};

export default SelectorCard;
