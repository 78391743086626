import Colors from "../../../theme/colors/colors";

export default {
    bodyWidth: {
        maxWidth: 1250,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: 32,
        paddingBottom: 58,
        flex: 1,
        height: "calc(100vh - 150px)"
    },
    footerLegal: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 60,
    },
    footerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        maxWidth: 1250,
        width: "100%",
        height: 60
    },
    footerInternal: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        width: "100%"
    },
    footerText: {
        fontSize: 18,
        color: Colors.white,
        fontFamily: "Barlow",
    },
    logoContainer: {
        width: 140,
        paddingLeft: 12,
        paddingRight: 12,
        display: "flex",
        height: 60
    },
    modalContent: {
        width: 200,
        display: 'flex',
        marginRight: 40,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}
