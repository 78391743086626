import React, {useState} from "react";

import {STRING} from "../../../constants/strings/strings";
import LOGO from "../../../assets/body/logo-allergan.png";
import GROUP from "../../../assets/body/logo-serv.png";

import styles from "./styles";
import "./styles.css";
import Modal from "../../units/Modal/Modal";
import Button from "../../units/Button/Button";

const Body = ({children, containerStyle = {}, showMeetingText = true, mobile = false}) => {

    const [modal, handleModal] = useState(false);

    if (mobile) {
        containerStyle = {
            ...containerStyle,
            padding: 8,
            margin: 0
        }
    }

    return (
        <div className="body-wrapper">
            <div style={{...styles.bodyWidth, ...containerStyle}}>
                {children}
            </div>
            <footer style={styles.footerWrapper}>
                <div style={styles.footerInternal}>
                    <div style={{...styles.logoContainer, justifyContent: 'flex-start'}}>
                        <img style={{height: 52, paddingBottom: 8}} src={LOGO} alt={'logo'}/>
                    </div>
                    <div style={styles.footerLegal}>
                        {showMeetingText && <p style={styles.footerText}>{STRING.FOOTER_TEXT}</p>}
                        <a href="#" className="links" onClick={() => handleModal(true)} style={{width: 100}}>Textos legales</a>
                    </div>
                    <div style={{...styles.logoContainer, justifyContent: 'flex-end'}}>
                        <img style={{height: 46, paddingBottom: 8}} src={GROUP} alt={'group'}/>
                    </div>
                </div>
                <Modal isVisible={modal}>
                        <div style={styles.modalContent}>
                            <a target="_blank" href="/legal-bases" className="links" style={{color: 'black'}}>{STRING.LEGAL_BASES}</a>
                            <a target="_blank" href="/legal-warning" className="links" style={{color: 'black'}}>{STRING.LEGAL_WARNING}</a>
                            <a target="_blank" href="/cookies-policy" className="links" style={{color: 'black'}}>{STRING.COOKIES_POLICY}</a>
                            <a target="_blank" href="/privacy-policy" className="links" style={{color: 'black'}}>{STRING.PRIVACY_POLICY}</a>
                            <br/>
                            <Button title={STRING.CLOSE} onClick={() => handleModal(false)}/>
                        </div>
                </Modal>

            </footer>
        </div>
    )
};

export default Body;
