import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Body from "../../../components/layout/Body/Body";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import Button from "../../../components/units/Button/Button";


import Colors from "../../../theme/colors/colors";
import {getSessionInfo, saveSessionInfo} from "../../../constants/room";
import {getDoctorInfo, getUserInfo} from "../../../constants/auth";
import {STRING} from "../../../constants/strings/strings";

import {getSessionStatusService} from "../../../data/service/medicoService";

import styles from "./styles";

const AffirmationRevealVisit = () => {

    const location = useLocation();
    const selectedCard = location?.state?.selectedCard;
    const afirmacion = location?.state?.afirmacion;

    const {meetingType, roomCode, affirmationId} = useParams();

    const navigate = useNavigate();

    const medico = getDoctorInfo();

    useEffect(() => {
        fetchSession()

    }, [])

    const fetchSession = () => {
        getSessionStatusService(roomCode)
            .then(r => {
                saveSessionInfo(r[0]);
            })
            .catch(err => {
                alert(err);
            })
    };

    const handleOnGoToAffirmations = () => {
        navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/visita`)
        // navigate(`/delegado/affirmations/${meetingType}/${roomCode}`)
    };

    return (
        <Body>
            <MainBackground enableBackButton>
                <div style={{textAlign: "center", width: '100%'}}>
                    <div style={{backgroundColor: 'white', border: '1px solid #DFDFDF', padding: 8, margin: '12px 0'}}>
                        <p style={{fontWeight: 800, color: Colors.silverBlue, fontSize: 20, marginTop: 0, marginBottom: 12}}>{afirmacion?.pregunta}</p>
                    </div>
                </div>
                <div style={{flex: 1, display: "flex", gap: 12, flexDirection: "column", alignItems: "center", paddingBottom: 60}}>
                    <VerdadOMitoCard verdad={selectedCard ==="Verdad"} text={selectedCard.toUpperCase()} shouldSelect={false}/>
                    {selectedCard === afirmacion?.respuesta
                        ? <p style={{
                            fontWeight: 800,
                            color: Colors.green,
                            fontSize: 20,
                            textAlign: "center",
                        }}>¡Respuesta correcta!</p>
                        : <p style={{
                            fontWeight: 800,
                            color: Colors.red,
                            fontSize: 20,
                            textAlign: "center",
                        }}>¡Respuesta incorrecta!</p>
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 50}}>
                    <div>
                        <Button title={STRING.CONTINUE} onClick={handleOnGoToAffirmations}/>
                    </div>
                </div>
            </MainBackground>
        </Body>
    )
};

export default AffirmationRevealVisit;
