export const STRING = {
    FOOTER_TEXT: "Reuniones interactivas en EMD",
    LANDING_TEXT: "",
    START: "Empezar",
    NEXT: "Siguiente",
    REGISTER_TITLE: "ES HORA DE REGISTRARTE",
    REGISTER_BUTTON: "Registrarme",
    NAME: "Nombre",
    EMAIL: "Email",
    PASSWORD: "Contraseña",
    REPEAT_PASSWORD: "Repetir contraseña",
    ACCEPT_CONDITIONS: "Acepto términos y condiciones",
    LOGIN_TITLE: "INICIA SESIÓN",
    LOGIN_BUTTON: "Iniciar sesión",
    LOGIN_ERROR_TITLE: "ERROR EN EL LOGIN",
    LOGIN_ERROR_MSG: "Vaya, parece que ha ocurrido un error, estamos mirando de solucionarlo. En caso de no solucionarse, contacta con nuestros agentes.",
    CLOSE: "Cerrar",
    PASSWORD_FORGOT: "He olvidado mi contraseña",
    SELECTOR_TITLE: "¿QUÉ HACEMOS HOY?",
    CONTINUE: "Continuar",
    MEETING: "REUNIÓN",
    VISIT: "VISITA",
    CREATE_ROOM_TITLE: "CREÉMOS UNA SALA",
    CREATE_ROOM: "Crear sala",
    ROOM_NAME: "Nombre de la sala",
    WAITING_ROOM_TITLE: "ESPERANDO A LOS ASISTENTES",
    AFFIRMATIONS: "AFIRMACIONES",
    ABOUT_YOU: "CUÉNTANOS SOBRE TI",
    LETS_GO: "Vamos allá",
    HOSPITAL: "Hospital",
    WAITING_MESSAGE: "Enseguida comenzará la sesión, estamos esperando al resto de participantes",
    TIME_TO_ANSWER: "ES EL MOMENTO DE RESPONDER",
    LEGAL_BASES: "Bases legales",
    LEGAL_WARNING: "Aviso legal",
    COOKIES_POLICY: "Política de Cookies",
    PRIVACY_POLICY: "Política de privacidad"
}
