import React, {useEffect, useState} from "react";
import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import Button from "../../../components/units/Button/Button";
import {STRING} from "../../../constants/strings/strings";
import {getSessionInfo} from "../../../constants/room";
import {getUserInfo} from "../../../constants/auth";
import {getAfirmacionService} from "../../../data/service/delegadoService";
import {useNavigate, useParams} from "react-router-dom";
import Colors from "../../../theme/colors/colors";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import {postAnswerMedicoService} from "../../../data/service/medicoService";

const AnswersScreen = () => {

    const [fetching, setFetching] = useState(false);
    const [posting, setPosting] = useState(false);

    const [selectedCard, setSelectedCard] = useState(null);

    const {meetingType, roomCode, affirmationId} = useParams();

    const navigate = useNavigate();

    const [afirmacion, setAfirmacion] = useState([]);

    const session = getSessionInfo();

    const medico = getUserInfo();

    useEffect(() => {
        fetchAfirmacion()
    }, [])

    const fetchAfirmacion = () => {
        setFetching(true);
        getAfirmacionService(affirmationId, roomCode)
            .then(r => {
                console.log(r);
                setAfirmacion(r)
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    const postAnswer = () => {
        if (posting) return;
        setPosting(true);
        postAnswerMedicoService(roomCode, affirmationId, medico?._id, selectedCard)
            .then(r => {
                console.log(r);
                setPosting(false);
                navigate(`/medico/meeting/${roomCode}/affirmationReveal/${affirmationId}`, {state: {selectedCard, afirmacion}})
            })
            .catch(err => {
                setPosting(false);
            })
    }


    return (
        <Body showMeetingText={false} mobile={true}>
            <MainBackground loading={fetching} mobile={true} showLogo={false}>
                <div style={{textAlign: "center", width: '100%'}}>
                    <p style={{fontWeight: 600, fontSize: 16, marginTop: 0, marginBottom: 4}}>{session?.nombre}</p>
                    <p style={{fontSize: 18}}>{medico?.nombre} - <span>{medico?.hospital}</span></p>
                    <div style={{backgroundColor: 'white', border: '1px solid #DFDFDF', padding: 8, margin: '12px 0'}}>
                        <p style={{fontWeight: 800, color: Colors.silverBlue, fontSize: 20, marginTop: 0, marginBottom: 12}}>{afirmacion?.pregunta}</p>
                    </div>

                </div>
                <div style={{flex: 1, display: "flex", gap: 12, flexDirection: "column", alignItems: "center", paddingBottom: 60}}>
                    <VerdadOMitoCard verdad={true} text={"VERDAD"} shouldSelect={selectedCard!==null}
                                     selected={selectedCard==="Verdad"} onCardClick={() => setSelectedCard("Verdad")}/>
                    <VerdadOMitoCard verdad={false} text={"MITO"} shouldSelect={selectedCard!==null}
                                     selected={selectedCard==="Mito"} onCardClick={() => setSelectedCard("Mito")}/>

                </div>
                <div style={{position: "absolute", bottom: 4, left: 0, display: "flex", justifyContent: "center", width: '100%',}}>
                    <div style={{maxWidth: 200}}>
                        <Button title={STRING.CONTINUE} onClick={postAnswer} disable={selectedCard===null} loading={posting}/>
                    </div>
                </div>
            </MainBackground>
        </Body>
    )
}

export default AnswersScreen;
