import Colors from "../../../theme/colors/colors";

export default {
    titleContainer: {
        textAlign: "center",
        width: '100%'
    },
    titleText: {
        fontFamily: "BebasNeue",
        fontSize: 28,
        color: Colors.silverBlue
    },
    titleDoctorInfo: {
        fontSize: 18,
        color: Colors.silverBlue,
        margin: '8px 0'
    },
    questionContainer: {
        backgroundColor: 'white',
        border: '1px solid #DFDFDF',
        padding: 8
    },
    questionText: {
        fontWeight: 800,
        color: Colors.silverBlue,
        fontSize: 20,
        marginTop: 0,
        marginBottom: 12
    },
    cardsContainer: {
        flex: 1,
        display: "flex",
        gap: 12,
        alignItems: "center",
        justifyContent: 'center',
        paddingBottom: 60
    },
    buttonContainer: {
        position: "absolute",
        bottom: 4,
        left: 0,
        display: "flex",
        justifyContent: "center",
        width: '100%'
    }
};
