import React, {useState} from "react";

import LOGO from "../../../assets/landing/logo-landing.png";

import "./styles.css";
import Loader from "../../units/loader/Loader";
import QRCode from "react-qr-code";
import Button from "../../units/Button/Button";
import BackButton from "../../units/BackButton/BackButton";

const MainBackground = ({ backButtonPress, title = "", children, showLogo = true, loading = false, mobile = false, roomCode=null, enableBackButton = false }) => {

    const qrUrl = `https://www.verdadomitoenemd.com/medico/${roomCode}`;
    const textUrl = `https://www.verdadomitoenemd.com/ medico/${roomCode}`;
    const [open, setOpen] = useState(false)

    return (
        <div className="background" style={mobile ? {margin: 8, marginBottom: 24, borderRadius: 8} : {}}>
            {showLogo && <img onClick={() => setOpen(!open)} className="background__logo" src={LOGO} alt={"Logo"}/>}
            <div className="background__title-container">
                {enableBackButton && <BackButton handleBackButton={backButtonPress}/>}
                <p className="background__title-text">{title}</p>
            </div>
            <div className="background__container" style={mobile ? {padding: 8} : {}}>
                {loading && <div style={{width: '100%', textAlign: "center"}}><Loader color={"green"}/></div>}
                {children}
            </div>
            {open &&
                    <div onClick={() => setOpen(false)} className="modal-container-qr">
                        <div className="modal-content-qr">
                            <QRCode
                                value={qrUrl}
                                level={"H"}
                                size={206}
                            />
                            <br/>
                            <p className="link-qr">{textUrl}</p>
                            <div>
                                <Button title={'Cerrar'} onClick={() => setOpen(false)}/>
                            </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default MainBackground;
