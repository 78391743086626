export default {
    input: {
        width: 344,
        height: 50,
        boxSizing: 'border-box',
        fontSize: 18,
        borderRadius: 2,
        padding: '12px 10px',
        outline: 'none',
        border: 'none'
    }
}
