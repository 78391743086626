import {DOCTOR_INFO, DOCTOR_TOKEN, TOKEN, USER_INFO} from "./constants";

export const saveToken = (token) => {
    localStorage.setItem(TOKEN, token);
}

export const clearToken = () => {
    localStorage.removeItem(TOKEN);
}

export const saveUserInfo = (user) => {
    localStorage.setItem(USER_INFO, JSON.stringify(user))
}

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(USER_INFO));
}

export const saveDoctorInfo = doctor => {
    localStorage.setItem(DOCTOR_INFO, JSON.stringify(doctor));
};

export const getDoctorInfo = () => {
    return JSON.parse(localStorage.getItem(DOCTOR_INFO));
};

export const saveDoctorToken = (token) => {
    localStorage.setItem(DOCTOR_TOKEN, token);
};

export const getDoctorToken = () => {
    return localStorage.getItem(DOCTOR_TOKEN)
};
