import React from "react";
import BackArrow from "../../../assets/body/back-arrow.png"
import "./styles.css";
import {useNavigate} from "react-router-dom";

const BackButton = ({handleBackButton}) => {

    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(-1);
        handleBackButton()
    }

    return (
        <div onClick={handleNavigation} style={{width: 30, height: 30, cursor: "pointer"}}>
            <img className="img-back" src={BackArrow} alt="back"/>
        </div>
    )
}

export default BackButton;
