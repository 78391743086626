import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {STRING} from "../../../constants/strings/strings";
import LOGO from "../../../assets/landing/logo-landing.png";
import SLOGAN from "../../../assets/landing/landing-slogan.png";

import Body from "../../../components/layout/Body/Body";
import styles from "./styles";
import Button from "../../../components/units/Button/Button";
import {loginDelegadoService} from "../../../data/service/medicoService";
import moment from "moment";
import {saveToken, saveUserInfo} from "../../../constants/auth";
import {saveSessionInfo} from "../../../constants/room";

const LandingPlayer = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const {roomCode} = useParams();

    const handleStartClick = () => {
        setLoading(true);
        loginDelegadoService(roomCode)
            .then(r => {
                saveToken(r?.token);
                saveSessionInfo(r?.sesion);
                navigate(`/medico/meeting/${roomCode}/register`)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                alert(err);
            })

    }

    return (
        <Body containerStyle={styles.container} showMeetingText={false}>
            <img src={LOGO} style={styles.logoImage} alt={"Logo"} />
            <img src={SLOGAN} style={styles.sloganImage} alt={"Slogan"} />
            <div style={styles.mainContainer}>
                <p style={styles.landingText}>{STRING.LANDING_TEXT}</p>
                <Button title={STRING.START} onClick={handleStartClick} loading={loading} />
            </div>
        </Body>
    )
};

export default LandingPlayer;
