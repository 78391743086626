import React from "react";

import Input from "../../units/Input/Input";

import styles from "./styles";
import {STRING} from "../../../constants/strings/strings";
import Checkbox from "../../units/Checkbox/Checkbox";
import Button from "../../units/Button/Button";
import {useNavigate} from "react-router-dom";

const FormRegister = ({onFormSubmitted, onInputChange, inputValue, onCheckboxChange, checkboxValue, loading}) => {

    const navigate = useNavigate();

    return (
        <form style={styles.formWrapper} onSubmit={onFormSubmitted}>
            <Input
                name="name"
                placeholder={STRING.NAME}
                onChange={onInputChange}
                value={inputValue?.name}
                type={"text"}
                customStyle={styles.input}
            />
            <Input
                name="email"
                placeholder={STRING.EMAIL}
                onChange={onInputChange}
                value={inputValue?.email}
                type={"email"}
                customStyle={styles.input}
            />
            <Input
                name="password"
                placeholder={STRING.PASSWORD}
                onChange={onInputChange}
                value={inputValue?.password}
                type={"password"}
                customStyle={styles.input}
            />
            <Input
                name="repeatPassword"
                placeholder={STRING.REPEAT_PASSWORD}
                onChange={onInputChange}
                value={inputValue?.repeatPassword}
                type={"password"}
                customStyle={styles.input}
            />
            <Checkbox
                text={STRING.ACCEPT_CONDITIONS}
                value={checkboxValue}
                onChange={onCheckboxChange}
            />
            <div style={styles.buttonWrapper}>
                <Button title={STRING.REGISTER_BUTTON} disable={!inputValue?.name || !inputValue?.email || !inputValue?.password || !inputValue?.repeatPassword || !inputValue?.checkbox}
                        loading={loading}
                />
            </div>
            <div style={{textAlign: "center", paddingTop: 12}}>
                <a href="" style={{textDecoration: 'underline', color: 'white'}} onClick={() => navigate("/delegado/login")}>Ya tienes cuenta? Inicia sesión.</a>
            </div>
        </form>
    )
};

export default FormRegister;
