import React from "react";

import "./styles.css";
import PlayerListItem from "../PlayerListItem/PlayerListItem";

const PlayersList = ({players}) => {
    return (
        <>
            <div className="list-background">
                <div className="list-content">
                    {players.map((player, index) => (
                        <PlayerListItem key={index} username={player.nombre} workplace={player.hospital} />
                    ))}
                    <p style={{fontWeight: "bold", textTransform: "uppercase", marginTop: 8}}>TOTAL: {players.length}</p>
                </div>
            </div>
        </>
    )
};

export default PlayersList;
