import React from "react";

import styles from "./styles";

const Checkbox = ({text = "", value = false, onChange}) => {
    return (
        <div style={styles.container}>
            <input style={styles.checkbox} name="checkbox" onChange={onChange} type="checkbox" checked={value} />
            <p style={styles.text}>{text}</p>
        </div>
    )
};

export default Checkbox;
