import React from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";

const LegalWarning = () => {
    return (
        <Body>
            <MainBackground>
                <p>En cumplimiento de lo dispuesto en el art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (LSSICE), ponemos a su disposici&oacute;n la informaci&oacute;n general, que incluye los datos de identificaci&oacute;n del titular de este sitio web:</p>
                <p><strong>Denominaci&oacute;n Social: Pulso Ediciones, S.L.U.</strong></p>
                <p><strong>Domicilio Social:</strong>&nbsp;Rambla de Celler, 117-119, Sant Cugat del Vall&egrave;s, 08172 Barcelona.<br /> <strong>CIF:</strong>&nbsp;B-08621476</p>
                <p><strong>Tel&eacute;fono:</strong>&nbsp;+34 93 589 62 64<br /> <strong>e-mail: </strong><a href="mailto:comunicacion@pulso.com">comunicacion@pulso.com</a></p>
                <p><strong>Inscrita en el Registro Mercantil de Barcelona:</strong>&nbsp;Tomo 30097, folio 71 Hoja n&ordm; B 95627 inscripci&oacute;n 11</p>
                <p><strong>Nombre del dominio:&nbsp;</strong><a href="http://www.verdadomitoenemd.com">www.verdadomitoenemd.com</a></p>
                <p><br /> </p>
                <p><strong>Pulso ediciones S.L.U.</strong> es el encargado del sitio web y pone a disposici&oacute;n de los usuarios el presente aviso legal para informar a todos los usuarios de cu&aacute;les son las condiciones generales de uso del sitio web.</p>
                <p>Todo usuario que acceda a este sitio web se compromete a la observancia y cumplimiento riguroso de las disposiciones aqu&iacute; se&ntilde;aladas, as&iacute; como a cualesquiera otras disposiciones legales que fuera de aplicaci&oacute;n. Por ello, se recomienda a los usuarios leer detenidamente las presentes condiciones de uso antes de utilizar el sitio web o la aplicaci&oacute;n. Si no estuviera de acuerdo, le rogamos que se abstenga de acceder al sitio web.</p>
                <p>Pulso se reserva el derecho a modificar cualquier tipo de informaci&oacute;n que no requiera de aceptaci&oacute;n expresa por parte del usuario y pudiera aparecer en el sitio web. En el caso de que dicha modificaci&oacute;n afectar&aacute; a los derechos del usuario, se le informar&aacute; previamente a su entrada en vigor y dado el caso a recabar de nuevo su consentimiento.</p>
                <p>&nbsp;</p>
                <p><strong>Responsabilidad</strong></p>
                <p><strong>&iquest;Verdad o Mito? en Edema Macular Diab&eacute;tico (en adelante EMD) </strong>es una actividad formativa que tiene como objetivo impulsar la excelencia en el manejo del <strong>EMD</strong> mediante una plataforma web, que permitir&aacute; a los usuarios participar en sesiones presenciales colectivas e individuales, respondiendo XX afirmaciones cl&iacute;nicas publicadas si &eacute;stas son<strong>&nbsp;verdad o mito y</strong> descubrir si albergan creencias no basadas en la evidencia sobre EMD.</p>
                <p><strong>&iquest;Verdad o Mito? en EMD </strong>est&aacute; dirigido a especialistas del &aacute;mbito de la Oftalmolog&iacute;a. El usuario reconoce y acepta que el uso de los servicios y/o contenidos del sitio web ser&aacute; bajo su exclusivo riesgo y responsabilidad. En ning&uacute;n caso, PULSO asumir&aacute; responsabilidad alguna derivada del uso que realice el usuario, especialmente en los casos en que dicho uso se realice incumpliendo lo dispuesto en las presentes condiciones de uso y/o la normativa aplicable.</p>
                <p>Cualquier decisi&oacute;n o actuaci&oacute;n, teniendo en cuenta las afirmaciones de verdad o mito, ser&aacute;n de responsabilidad &uacute;nica y exclusiva de quien la aplique, eximiendo de toda responsabilidad a los promotores, autores y editores de&nbsp;<strong>&iquest;Verdad o Mito? en EMD.</strong></p>
                <p>El comit&eacute; cient&iacute;fico y la editorial se han esforzado para ofrecer con el m&aacute;ximo rigor, exactitud e idoneidad, la informaci&oacute;n contenida en esta herramienta multiplataforma, validado con mero detalle el contenido, el algoritmo as&iacute; como las normativas de referencia utilizadas para que as&iacute; la informaci&oacute;n ofrecida est&eacute; de acuerdo con las recomendaciones y pr&aacute;cticas cl&iacute;nicas actuales.</p>
                <p>La medicina es una ciencia en continua evoluci&oacute;n. A medida que pasa el tiempo, los tratamientos, ecuaciones o normativas de referencia, experimentan cambios. Por tanto, el editor, el promotor, el comit&eacute; cient&iacute;fico de&nbsp;<strong>&iquest;Verdad o Mito? en EMD </strong>no asumen responsabilidad u obligaci&oacute;n legal derivada de los da&ntilde;os y perjuicios causados por las decisiones tomadas por el usuario en base a la informaci&oacute;n proporcionada por&nbsp;<strong>&iquest;Verdad o Mito? en EMD </strong>ni de posibles inexactitudes, omisiones o errores contenidos en la misma, ni de los da&ntilde;os o perjuicios en el&nbsp;<em>software</em>&nbsp;o&nbsp;<em>hardware</em>&nbsp;del usuario.</p>
                <p>Este sitio web ha sido revisado y probado para que funcione correctamente. No obstante, PULSO no descarta la posibilidad de que existan ciertos errores de programaci&oacute;n o que acontezcan causas de fuerza mayor (cat&aacute;strofes naturales, huelgas) o circunstancias semejantes que hagan imposible el acceso a la p&aacute;gina web.</p>
                <p>Corresponde al usuario proteger su dispositivo frente a posibles virus,&nbsp;<em>malware</em>, ataques de terceros o cualquier otro elemento inform&aacute;tico da&ntilde;ino que pudiera afectar a su dispositivo m&oacute;vil, disponiendo para ello de herramientas adecuadas para la detecci&oacute;n y desinfecci&oacute;n de programas maliciosos o cualquier otro elemento inform&aacute;tico da&ntilde;ino.</p>
                <p>El usuario se compromete a utilizar el sitio web de conformidad con la ley, la moral, el orden p&uacute;blico y las presentes condiciones de uso. Asimismo, se compromete hacer un uso adecuado de los contenidos y servicios ofrecidos a trav&eacute;s del sitio web y la aplicaci&oacute;n, a no emplearlos para realizar actividades il&iacute;citas o constitutivas de delito, que atenten contra terceros y/o que infrinjan los derechos de propiedad intelectual e industrial de terceros, o cualesquiera otras normas del ordenamiento jur&iacute;dico. PULSO podr&aacute;, en cualquier momento, limitar o interrumpir el uso que realice del sitio web.</p>
                <p>&nbsp;</p>
                <p><strong>Propiedad intelectual e industrial</strong></p>
                <p>El sitio web, incluyendo a t&iacute;tulo enunciativo, pero no limitativo su programaci&oacute;n, edici&oacute;n, compilaci&oacute;n y dem&aacute;s elementos necesarios para su funcionamiento (los dise&ntilde;os, logotipos, texto, material formativo y/o gr&aacute;ficos) son propiedad de PULSO o en su caso este dispone de licencia o autorizaci&oacute;n expresa por parte de los autores.</p>
                <p>Independientemente de la finalidad para la que sean destinados, tanto la reproducci&oacute;n total o parcial, el uso, la explotaci&oacute;n, la distribuci&oacute;n como la comercializaci&oacute;n de todo lo contenido en el sitio web y la aplicaci&oacute;n requieren de la autorizaci&oacute;n previa, por escrito, por parte de PULSO. Cualquier uso no autorizado previamente por parte de este ser&aacute; considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.</p>
                <p>No est&aacute; permitido utilizar, compartir ni difundir contenidos que pudieran constituir directa o indirectamente promoci&oacute;n de medicamentos de prescripci&oacute;n reproducidos durante la formaci&oacute;n a trav&eacute;s de redes sociales u otros canales, soportes o medios abiertos al p&uacute;blico.</p>
                <p>Los dise&ntilde;os, logotipos, textos y/o gr&aacute;ficos ajenos a PULSO que pudieran aparecer en el sitio web o la aplicaci&oacute;n pertenecen a sus respectivos propietarios, quienes ser&aacute;n responsables de cualquier posible controversia que pudiera suscitarse en ellos. En todo caso, PULSO cuenta con la autorizaci&oacute;n expresa y previa por parte de los mismos.</p>
                <p>PULSO reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e intelectual, no implicando su sola menci&oacute;n o aparici&oacute;n en el sitio web o aplicaci&oacute;n la existencia de derechos o responsabilidad alguna de PULSO sobre los mismos, como tampoco respaldo, patrocinio o recomendaci&oacute;n por parte del mismo.</p>
                <p>Para realizar cualquier tipo de observaci&oacute;n respecto a posibles incumplimientos de los derechos de propiedad intelectual o industrial, as&iacute; como sobre cualquiera de los contenidos del sitio web o la aplicaci&oacute;n, puede hacerlo a trav&eacute;s del siguiente correo electr&oacute;nico:&nbsp;<a href="mailto:comunicacion@pulso.com">comunicacion@pulso.com</a></p>
                <p>&nbsp;</p>
                <p><strong>Ley aplicable y jurisdicci&oacute;n</strong></p>
                <p>Para la resoluci&oacute;n de cualquier controversia que pudiera suscitarse con relaci&oacute;n al acceso o el uso de esta p&aacute;gina web o de las actividades desarrolladas aqu&iacute;, se someter&aacute;n ambas partes a la jurisdicci&oacute;n de los Juzgados y Tribunales de la ciudad de Barcelona.</p>
                <p>Los usuarios de esta p&aacute;gina web son conscientes de todo lo expuesto y lo aceptan voluntariamente.</p>
                <p>&nbsp;</p>
            </MainBackground>
        </Body>
    )
}

export default LegalWarning;
