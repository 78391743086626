import Colors from "../../../theme/colors/colors";

export default {
    container: {
        border: 'none',
        minWidth: 200,
        height: 52,
        borderRadius: 12,
        backgroundColor: Colors.brass,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        color: Colors.white,
        fontFamily: "Barlow"
    }
}
