export default {
    mainContainer: {
        paddingBottom: 30,
        maxWidth: 814,
        alignSelf: 'center',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 14
    }
};
