import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import SLOGAN from "../../../assets/landing/landing-slogan.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import PlayerRegister from "../../../components/composed/PlayerRegister/PlayerRegister";

import styles from "./styles";
import {loginDelegadoService, registerMedicoService} from "../../../data/service/medicoService";
import {getUserInfo, saveDoctorInfo, saveDoctorToken, saveUserInfo} from "../../../constants/auth";
import {getSessionInfo} from "../../../constants/room";

const RegisterVisitPlayer = () => {
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleOnFormSubmitted = async (event) => {
        event.preventDefault();
        setLoading(true);
        const sessionId = getSessionInfo()?._id;

        try {
            const loginResponse = await loginDelegadoService(sessionId);
            const registerDoctorResponse = await registerMedicoService(formData.name, formData.hospital, sessionId, loginResponse?.token);
            saveDoctorToken(loginResponse?.token);
            saveDoctorInfo(registerDoctorResponse);
            navigate(`/delegado/affirmations/visit/${sessionId}`)
            setLoading(false);

        } catch (error) {
            setLoading(false);
            alert(error);
        }

        /*loginDelegadoService(sessionId)
            .then(response => {
                console.log("LOGIN RESPONSE => ", response)

                registerMedicoService(formData.name, formData.hospital, sessionId, response.token)
                    .then(r => {
                        // saveUserInfo(r);
                        navigate(`/delegado/affirmations/visit/${sessionId}`)
                        setLoading(false);
                    })
            })
            .catch(err => {
                setLoading(false);
                alert(err);
            })*/
    };

    const handleOnInputChange = event => {
        const newFormData = {...formData, [event.target.name] : event.target.value};
        setFormData(newFormData);
    };

    return (
        <Body containerStyle={styles.container}>
            <img src={LOGO} style={styles.logo} alt="Logo" />
            <p style={styles.title}>{STRING.VISIT}</p>
            <PlayerRegister
                onFormSubmitted={handleOnFormSubmitted}
                onInputChange={handleOnInputChange}
                inputValue={formData}
                loading={loading}
            />
        </Body>
    )
}

export default RegisterVisitPlayer;
