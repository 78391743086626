import {TOKEN, USER_INFO} from "./constants";

export const saveSessionInfo = (session) => {
    localStorage.setItem("session", JSON.stringify(session))
}

export const getSessionInfo = () => {
    return JSON.parse(localStorage.getItem("session"));
}

export const generateMedicoString = (medico) => {
    return medico?.nombre + (medico?.hospital ? " ("+medico.hospital+")" : "")
}
