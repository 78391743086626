import React, {useEffect, useState} from 'react';
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import {Button, Tooltip} from "@mui/material";
import {Summarize} from "@mui/icons-material";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import {generateMedicoString} from "../../../../constants/room";


const InformeReunionExcelDownload = ({
                                         delegado,
                                         nombreSala,
                                         fecha,
                                         medicosRegistrados,
                                         afirmacionesTrabajadas,
                                         porcAciertosTotales,
                                         respuestasCorrectas,
                                         respuestasIncorrectas,
                                         isVisita
                                     }) => {

    const respuestas = respuestasCorrectas.concat(respuestasIncorrectas);
    respuestas.forEach((resp) => {
        const afirm = afirmacionesTrabajadas.find((el) => el._id === resp.afirmacion._id)
        const medico = medicosRegistrados.find((el) => el._id === resp.medico._id)
        resp.afirmacionId = afirm._id;
        resp.afirmacionExterna = afirm.titulo_externo;
        resp.afirmacionPregunta = afirm.pregunta;
        resp.medicoId = medico._id;
        resp.medicoNombre = generateMedicoString(medico);

    })


    const reunion = [{
        "delegado": delegado,
        "nombre_sala": nombreSala,
        "fecha": fecha,
        "tipo": isVisita ? "Visita" : "Reunión"
    }]

    return (
        <ExcelFile
            element={<Tooltip title={"Descargar en Excel"}><Button style={{margin: 0, padding: 0, minWidth: 32}}><Summarize /></Button></Tooltip>}
            filename={fecha+"-"+nombreSala}>
            <ExcelSheet data={reunion} name={"Info"}>
                <ExcelColumn label={"delegado"} value={'delegado'}/>
                <ExcelColumn label={"nombre_sala"} value={'nombre_sala'}/>
                <ExcelColumn label={"fecha"} value={'fecha'}/>
                <ExcelColumn label={"tipo"} value={'tipo'}/>
            </ExcelSheet>
            <ExcelSheet data={afirmacionesTrabajadas} name={"Afirmaciones trabajadas"}>
                <ExcelColumn label={"_id"} value={'_id'}/>
                <ExcelColumn label={"titulo_externo"} value={'titulo_externo'}/>
                <ExcelColumn label={"pregunta"} value={'pregunta'}/>
                <ExcelColumn label={"respuesta"} value={'respuesta'}/>
            </ExcelSheet>
            <ExcelSheet data={medicosRegistrados} name={"Médicos registrados"}>
                <ExcelColumn label={"_id"} value={'_id'}/>
                <ExcelColumn label={"nombre"} value={'nombre'}/>
                <ExcelColumn label={"hospital"} value={'hospital'}/>
            </ExcelSheet>
            <ExcelSheet data={respuestas} name={"Respuestas"}>
                <ExcelColumn label={"id_afirmacion"} value={'afirmacionId'}/>
                <ExcelColumn label={"afirmacion"} value={'afirmacionExterna'}/>
                <ExcelColumn label={"pregunta"} value={'afirmacionPregunta'}/>
                <ExcelColumn label={"medico_id"} value={'medicoId'}/>
                <ExcelColumn label={"medico_nombre"} value={'medicoNombre'}/>
                <ExcelColumn label={"respuesta_usuario"} value={'respuesta_usuario'}/>
                <ExcelColumn label={"correcta"} value={'correcta'}/>
            </ExcelSheet>
        </ExcelFile>

    );
}



export default InformeReunionExcelDownload
