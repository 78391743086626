import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {getAfirmacionService, updateSessionAffirmationService} from "../../../data/service/delegadoService";
import {postAnswerMedicoService} from "../../../data/service/medicoService";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";
import Button from "../../../components/units/Button/Button";

import {getDoctorInfo, getDoctorToken} from "../../../constants/auth";
import {STRING} from "../../../constants/strings/strings";

import styles from "./styles";

const AffirmationAnswerVisit = () => {
    const [fetching, setFetching] = useState(false);
    const [posting, setPosting] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [afirmacion, setAfirmacion] = useState([]);

    const {meetingType, roomCode, affirmationId} = useParams();
    const navigate = useNavigate();
    const medico = getDoctorInfo();

    useEffect(() => {
        fetchAfirmacion()
        enableAffirmation(affirmationId);

    }, [])

    const enableAffirmation = (affirmationId = null) => {
        updateSessionAffirmationService(roomCode, affirmationId)
            .then(r => {
                console.log(r);
                setFetching(false);
            })
            .catch(err => {
                alert("No se ha podido activar la pregunta. Compruebe la conexión y recargue la página para volver a intentarlo")
                setFetching(false);
            })
    }

    const fetchAfirmacion = () => {
        setFetching(true);
        getAfirmacionService(affirmationId, roomCode)
            .then(r => {
                console.log(r);
                setAfirmacion(r)
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    };

    const postAnswer = () => {
        if (posting) return;
        setPosting(true);
        const token = getDoctorToken();
        postAnswerMedicoService(roomCode, affirmationId, medico?._id, selectedCard, token)
            .then(r => {
                console.log(r);
                setPosting(false);
                navigate(`/delegado/${meetingType}/${roomCode}/affirmationReveal/${affirmationId}`, {state: {selectedCard, afirmacion}})
            })
            .catch(err => {
                setPosting(false);
            })
    };

    return (
        <Body>
            <MainBackground enableBackButton title={afirmacion?.titulo_externo ?  afirmacion?.titulo_externo : "CARGANDO..."} loading={fetching}>
                <div style={styles.titleContainer}>
                    <p style={styles.titleText}>{STRING.TIME_TO_ANSWER}</p>
                    <div style={styles.questionContainer}>
                        <p style={styles.questionText}>{afirmacion?.pregunta}</p>
                    </div>
                </div>

                <div style={styles.cardsContainer}>
                    <VerdadOMitoCard verdad={true} text={"VERDAD"} shouldSelect={selectedCard!==null}
                                     selected={selectedCard==="Verdad"} onCardClick={() => setSelectedCard("Verdad")}/>
                    <VerdadOMitoCard verdad={false} text={"MITO"} shouldSelect={selectedCard!==null}
                                     selected={selectedCard==="Mito"} onCardClick={() => setSelectedCard("Mito")}/>
                </div>

                <div style={styles.buttonContainer}>
                    <div style={{maxWidth: 200}}>
                        <Button title={STRING.CONTINUE} onClick={postAnswer} disable={selectedCard===null} loading={posting}/>
                    </div>
                </div>
            </MainBackground>
        </Body>
    )
};

export default AffirmationAnswerVisit;
