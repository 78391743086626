export default {
    white: "#FFFFFF",
    brass: "#B3BB45",
    davyGray: "#575757",
    sage: "#BBBE8F",
    silverSand: "#C4C4C4",
    silverBlue: "#4C687B",
    green: '#396435',
    red: '#ae2f2f'
}
