import React from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";

const LegalBases = () => {
    return (
        <Body>
            <MainBackground>
                <p><strong>Verdad O mito en Edema macular diabetico (emd): afirmaciones verdad o mito en emd de allergan&ndash; BASES LEGALES</strong></p>
                <p>La participaci&oacute;n en el programa Verdad o Mito en EMD queda sujeta a la aceptaci&oacute;n de las siguientes bases legales (en adelante, las Bases).</p>
                <ol>
                    <li><strong>Empresa organizadora y per&iacute;odo</strong></li>
                </ol>
                <p>&nbsp;PULSO EDICIONES S.L.U, (en adelante PULSO) con domicilio social en Rambla del Celler, 117-119, 08172 Sant Cugat del Vall&eacute;s (Barcelona), con CIF B-08621476 (que act&uacute;a como organizador y secretar&iacute;a t&eacute;cnica del programa denominado Verdad o Mito en EMD (en adelante, el Programa), en el que se requiere el registro del participante en la plataforma www.verdadomitoenemd.com</p>
                <p>De otra parte, ALLERGAN AN ABBVIE COMPANY (en adelante, &ldquo;ALLERGAN&rdquo;). Todo ello en cumplimiento con la norma vigente, en particular con los C&oacute;digos de la EFPIA y de Farmaindustria. ALLERGAN actuar&aacute; como patrocinador del Programa.</p>
                <p>Verdad o Mito en EMD dar&aacute; comienzo el 03 de Mayo de 2022 a las 00:00 horas.&nbsp;</p>
                <ol>
                    <li><strong>Convocatoria</strong></li>
                </ol>
                <p>Las presentes bases y condiciones generales se refieren a la convocatoria del programa Verdad o Mito en EMD.</p>
                <p>La participaci&oacute;n en el programa tiene car&aacute;cter gratuito y la inscripci&oacute;n podr&aacute; realizarse mediante registro a trav&eacute;s de&nbsp;la plataforma www.verdadomitoenemd.com.</p>
                <ol>
                    <li><strong>Requisitos para la participaci&oacute;n en el programa</strong></li>
                </ol>
                <p>El programa est&aacute; exclusivamente dirigido a:</p>
                <ul>
                    <li>Profesionales Sanitarios a t&iacute;tulo individual de &aacute;mbito de la Oftalmologia</li>
                </ul>
                <p>No podr&aacute;n participar en el programa:</p>
                <ul>
                    <li>Empleados de <strong>Pulso </strong>(incluidos sus c&oacute;nyuges e hijos que convivan con aquellos),</li>
                    <li>Agencias de&nbsp;<em>marketing</em>, publicidad o proveedores implicados en el programa.</li>
                </ul>
                <p>Pulso, como organizador del programa, se reserva el derecho de excluir a aquellos participantes que infrinjan o no cumplan estos requisitos de participaci&oacute;n o alg&uacute;n otro punto de las bases.</p>
                <p>A estos efectos, Pulso solicitar&aacute; a todos los profesionales m&eacute;dicos participantes los datos personales y profesionales necesarios para confirmar el cumplimiento de las bases. La falta de entrega de la informaci&oacute;n solicitada conllevar&aacute; la no admisi&oacute;n del participante.</p>
                <ol>
                    <li><strong>Forma de Registro en el programa, datos a aportar y uso de los datos por parte de Pulso.</strong></li>
                </ol>
                <p>Los participantes en el programa deber&aacute;n:</p>
                <ol>
                    <ul>
                        <li>&nbsp;Registrarse en la plataforma del programa Verdad o Mito en EMD a trav&eacute;s de <a href="http://www.verdadomitoenemd.com">www.verdadomitoenemd.com</a> completando la plantilla de datos personales y profesionales que resulte requerida.</li>
                        <li>Aceptar las condiciones del programa, pol&iacute;tica de privacidad y aviso legal.</li>
                    </ul>
                </ol>
                <p>Con su participaci&oacute;n en el programa, el participante no podr&aacute; contravenir las presentes bases, ni realizar acciones contrarias al orden p&uacute;blico y a la moral, de contenido pol&iacute;tico, racista, ofensivo, difamatorio, violento, pornogr&aacute;fico, agresivo, que vulnere derechos de terceros o que afecte directa o indirectamente a la imagen y al prestigio de Pulso y/o ALLERGAN.</p>
                <ol>
                    <li><strong>Comit&eacute; Cient&iacute;fico </strong></li>
                </ol>
                <p>Comit&eacute; Cient&iacute;fico&nbsp;de Verdad o Mito en EMD estar&aacute; formado por 4 miembros: Dr. Javier Zarranz-Ventura, Dra. Maribel L&oacute;pez G&aacute;lvez, Prof. Javier Ascaso y Dr. Enrique Cervera (v&eacute;ase miembros del Comit&eacute; Cient&iacute;fico en www.verdadomitoenemd.com)</p>
                <p>Todos los miembros del comit&eacute; cient&iacute;fico son profesionales sanitarios (profesionales m&eacute;dicos del &aacute;mbito de la oftalmolog&iacute;a) de reconocido prestigio.</p>
                <p>ALLERGAN ha designado a los miembros de este comit&eacute; cient&iacute;fico con el fin de garantizar que cuentan con las cualidades, experiencia y conocimientos suficientes para la valoraci&oacute;n en cuesti&oacute;n, as&iacute; como para asegurar una representaci&oacute;n equitativa de todas las &aacute;reas sanitarias a nivel nacional que podr&aacute;n participar en el Programa.</p>
                <p>&iquest;Qu&eacute; funciones realizar&aacute; el Comit&eacute; Cient&iacute;fico?</p>
                <ol>
                    <ul>
                        <li>Velar&aacute; por el cumplimiento de las Condiciones establecidas en las presentes Bases Legales.</li>
                        <li>Elaborar&aacute; el contenido de las 15 afirmaciones a incluir en la web del programa sobre las que versar&aacute;n la tem&aacute;tica de &eacute;ste.</li>
                        <li>Participar&aacute;n en la grabaci&oacute;n de los videos explicativos pertinentes a las afirmaciones asignadas por la figura de coordinaci&oacute;n del programa.</li>
                    </ul>
                </ol>
                <p>&nbsp;</p>
                <ol>
                    <li><strong>Fases del programa y descripci&oacute;n de las mismas</strong></li>
                </ol>
                <p><strong>&nbsp;</strong></p>
                <ol>
                    <ul>
                        <li><u>a, Fase 1&ordf;. Registro de los participantes </u></li>
                    </ul>
                </ol>
                <p>En esta fase los usuarios podr&aacute;n pre registrarse en la web del programa Verdad o Mito en EMD desde el inicio de cada sesi&oacute;n moderada, o, cuando la persona con permisos habilite la opci&oacute;n de registro con el/los asistente/s. &nbsp;</p>
                <p>El registro del participante en la plataforma &iquest;Verdad o Mito? en EMD debe realizarse cumplimentando los siguientes campos del formulario de registro (*datos obligatorios):</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nombre*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Apellido*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Centro/Instituci&oacute;n.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Correo electr&oacute;nico*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confirmar correo electr&oacute;nico*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Contrase&ntilde;a*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Confirmar contrase&ntilde;a*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Leer y aceptar las bases legales, pol&iacute;ticas de privacidad y aviso legal*.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aceptar, o no, recibir comunicaciones y actividades de informaci&oacute;n cient&iacute;fica, promocional e institucional del patrocinador del Programa (ALLERGAN) por medios telem&aacute;ticos.</p>
                <p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Aceptar, o no, recibir notificaciones de otros productos relacionados al tema de esta formaci&oacute;n por parte de la editorial.</p>
                <ol>
                    <li><u>Fase2&ordf;. Desarrollo del programa </u></li>
                </ol>
                <p>Una vez registrado, el usuario podr&aacute; empezar a interactuar dentro del programa y acceder a las diferentes afirmaciones propuestas por el comit&eacute; cient&iacute;fico del programa con las que retar&aacute;n los conocimientos de los usuarios tanto en las sesiones moderadas como individuales.</p>
                <p>&nbsp;</p>
                <ol start="7">
                    <li><strong>Utilizaci&oacute;n del nombre y de la imagen de los participantes</strong></li>
                </ol>
                <p>Ni PULSO ni ALLERGAN difundir&aacute;n ni utilizar&aacute;n de forma publicitaria los datos personales aportados por los participantes, mediante cualquier tipo de medio o soporte (incluyendo papel, digital, redes sociales, web, v&iacute;deo o comunicaciones en general). Los datos solamente podr&aacute;n difundirse en relaci&oacute;n con la presente actividad.</p>
                <p>La presente autorizaci&oacute;n tiene un &aacute;mbito geogr&aacute;fico nacional y se otorga con car&aacute;cter gratuito. La presente autorizaci&oacute;n se otorga durante el per&iacute;odo m&aacute;ximo permitido por la ley.</p>
                <ol start="8">
                    <li><strong>Derechos de propiedad intelectual e industrial</strong></li>
                </ol>
                <p>La participaci&oacute;n en el programa www.verdadomitoenemd.com no otorga a los participantes, ning&uacute;n derecho de ninguna naturaleza, incluido, pero no limitado, a derechos de propiedad intelectual e industrial sobre la p&aacute;gina&nbsp;www.verdadomitoenemd.com</p>
                <p>El participante deber&aacute; abstenerse de obtener, o intentar obtener, o de usar la p&aacute;gina web&nbsp;www.verdadomitoenemd.com por medios o procedimientos distintos de los que en cada caso se hayan puesto a su disposici&oacute;n o indicado al efecto. El participante deber&aacute; respetar en todo momento todos los derechos de propiedad intelectual e industrial sobre la p&aacute;gina web&nbsp;www.verdadomitoenemd.com titularidad de Pulso.</p>
                <ol start="9">
                    <li><strong>Datos personales</strong></li>
                </ol>
                <p>Todos los datos solicitados en el registro son obligatorios para participar en el programa. La informaci&oacute;n acerca del tratamiento de datos personales se puede encontrar en la Pol&iacute;tica de Privacidad de www.verdadomitoenemd.com</p>
                <ol start="10">
                    <li><strong>Reclamaciones</strong></li>
                </ol>
                <p>Cualquier reclamaci&oacute;n relacionada con las presentes bases debe enviarse por escrito a la atenci&oacute;n del Departamento de Formaci&oacute;n de Pulso, a la siguiente direcci&oacute;n: Rambla del Celler, 117-119, 08172 Sant Cugat del Vall&eacute;s, Barcelona, dentro del plazo de los 30 d&iacute;as siguientes a la fecha l&iacute;mite de participaci&oacute;n en el programa, como se indica en estas bases. Transcurrido dicho plazo, Pulso no atender&aacute; ninguna reclamaci&oacute;n.</p>
                <ol start="11">
                    <li><strong>Otras condiciones generales</strong></li>
                </ol>
                <p><strong>&nbsp;</strong></p>
                <ol>
                    <li>Pulso podr&aacute; requerir que la identidad de los participantes sea acreditada por medio de documentos oficiales.</li>
                    <li>En el caso de detecci&oacute;n de anomal&iacute;as o sospechas de que un participante est&aacute; impidiendo el normal desarrollo de su participaci&oacute;n, mediante cualquier procedimiento, t&eacute;cnico o inform&aacute;tico, PULSO podr&aacute;, de forma unilateral, eliminar la inscripci&oacute;n de ese participante. Por tanto, Pulso se reserva el derecho de eliminar de la actividad a cualquier participante que evidencie o del que se sospeche una actuaci&oacute;n irregular, sin necesidad de notificaci&oacute;n alguna.</li>
                    <li>En caso de que este Programa no pudiera realizarse, bien por fraudes detectados, errores t&eacute;cnicos o inform&aacute;ticos, o por cualquier otro motivo que no est&eacute; bajo el control del promotor o que afecte al normal desarrollo de la actividad, Pulso se reserva el derecho a cancelar, modificar o suspender la actividad.</li>
                    <li>Pulso se reserva, asimismo, el derecho a realizar modificaciones o a&ntilde;adir anexos sucesivos sobre la actividad o las presentes bases legales, siempre que se comuniquen debidamente a los participantes.</li>
                    <li>Pulso se reserva el derecho de excluir a los participantes que, por su conducta o intervenci&oacute;n, interfieran en el buen funcionamiento de la actividad.</li>
                    <li>Facebook, Instagram, Twitter u otras redes sociales no patrocinan, ni administran ni est&aacute;n asociadas o vinculadas de modo alguno a las actividades o servicios de Pulso. Por su parte, Pulso no patrocina, ni administra ni est&aacute; asociada o vinculada de modo alguno a las actividades o servicios de Facebook, Instagram, Twitter u otras redes sociales.</li>
                </ol>
                <p>&nbsp;</p>
                <ol start="12">
                    <li><strong>Ley aplicable y jurisdicci&oacute;n</strong></li>
                </ol>
                <p>Las presentes bases y condiciones generales se rigen e interpretan de conformidad con el Derecho espa&ntilde;ol.</p>
                <p>Las partes acuerdan someterse expresamente a los juzgados y tribunales de la ciudad de Barcelona para la resoluci&oacute;n de cualquier disputa o controversia que pudiera surgir en relaci&oacute;n con estas bases y condiciones generales, con renuncia expresa de las partes a cualquier otro fuero que pudiera corresponderles.</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </MainBackground>
        </Body>
    )
}

export default LegalBases;
