import axios from 'axios';
import {TOKEN} from "../../constants/constants";


const axiosService = axios.create({
    baseURL: "/cockpit/api",
    responseType: "json",
    withCredentials: false,

    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});

axiosService.interceptors.request.use(
    async (request) => {
        console.info('Request interceptor =>', request);
        let token;

        if (!!request.headers["Cockpit-Token"]) {
            token = request.headers["Cockpit-Token"];
        } else {
            token = localStorage.getItem(TOKEN);
        }
        if (request.url.includes("login")) return request;

        //console.log(token);
        request.headers['Cockpit-Token'] = token
        return request;
    },
    error => {
        console.error('Request interceptor error =>', error)
        return Promise.reject(error);
    }
)

axiosService.interceptors.response.use(
    (response) => {
        console.info('Response interceptor =>', response);
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            alert("Tu sesión ha expirado. Por favor, inicia sesión de nuevo");
        }
        console.error('Response interceptor error =>', error)
        return Promise.reject(error);
    }
)

export default axiosService;
