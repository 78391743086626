import React from "react";
import {useNavigate} from "react-router-dom";

import {STRING} from "../../../constants/strings/strings";
import LOGO from "../../../assets/landing/logo-landing.png";
import SLOGAN from "../../../assets/landing/landing-slogan.png";

import Body from "../../../components/layout/Body/Body";
import styles from "./styles";
import Button from "../../../components/units/Button/Button";

const Landing = () => {
    const navigate = useNavigate();

    return (
        <Body containerStyle={styles.container}>
            <div style={styles.topContainer}>
                <img src={LOGO} style={styles.logoImage} alt={"Logo"} />
                <img src={SLOGAN} style={styles.sloganImage} alt={"Slogan"} />
                <br />
                <br />
                <p style={styles.landingText}>{STRING.LANDING_TEXT}</p>
            </div>
            <div style={{display: 'flex', gap: 32}}>
                <Button title={STRING.LOGIN_BUTTON} onClick={() => navigate("/delegado/login")} />
            </div>
        </Body>
    )
};

export default Landing;
