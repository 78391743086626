import Colors from "../../../theme/colors/colors";

export default {
    container: {
        alignItems: 'center',
        paddingTop: 120,
        justifyContent: 'space-between'
    },
    logoImage: {
        width: '90%'
    },
    sloganImage: {
        width: '50%'
    },
    landingText: {
        fontFamily: "BebasNeue",
        textAlign: 'center',
        fontSize: 32,
        color: Colors.white,
        marginBottom: 24
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16
    }
};
