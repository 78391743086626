import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import LOGO from "../../../assets/landing/logo-landing.png";
import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import Button from "../../../components/units/Button/Button";
import SelectorCard from "../../../components/units/SelectorCard/SelectorCard";

import styles from "./styles";
import {getUserInfo} from "../../../constants/auth";

const DynamicSelector = () => {
    const [cardStatus, setCardStatus] = useState({});
    const navigate = useNavigate();

    const handleOnCardClick = prop => {
        const newCardStatus = {[prop]: true}
        setCardStatus(newCardStatus)
    };

    const handleOnContinueClick = () => {
        const meetingType = Object.keys(cardStatus)[0];
        if (meetingType === "meeting") navigate(`/delegado/room-creator/meeting`);
        else if (meetingType === "visit") {
            const delegadoId = getUserInfo()?._id;
            navigate(`/delegado/room-creator/${meetingType}`, {state: {delegadoId}});
        }
    };

    const handleOnCheckDisability = () => {
        const status = Object.values(cardStatus)
        return status.every(item => !item)
    };

    const isDefaultCardStatus = () => {
        return Object.keys(cardStatus).length === 0;
    };

    return (
        <Body>
            <div style={styles.container}>
                <img style={styles.logo} src={LOGO} alt={"Logo"} />
                <p style={styles.title}>{STRING.SELECTOR_TITLE}</p>

                <div style={styles.cardsContainer}>
                    <div style={styles.singleCardWrapper}>
                        <SelectorCard
                            text={STRING.MEETING}
                            onCardClick={() => handleOnCardClick("meeting")}
                            isActive={cardStatus?.meeting}
                            isDefaultStatus={isDefaultCardStatus()}
                        />
                    </div>
                    <div style={styles.singleCardWrapper}>
                        <SelectorCard
                            text={STRING.VISIT}
                            onCardClick={() => handleOnCardClick("visit")}
                            isActive={cardStatus?.visit}
                            isDefaultStatus={isDefaultCardStatus()}
                        />
                    </div>
                </div>

                <div>
                    <Button title={STRING.CONTINUE} onClick={handleOnContinueClick} disable={handleOnCheckDisability()}/>
                </div>
            </div>
        </Body>
    )
};

export default DynamicSelector;
