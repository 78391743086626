

export default {
    title: {
        color: 'white',
        fontSize: 22,
        margin: '40px 16px',
        fontWeight: '600',
        textAlign: 'center',
        marginBottom: 0
    },
    logo: {
        height: 90
    },
    sloganImage: {
        width: '50%'
    },
    mainContainer: {
        paddingTop: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}
