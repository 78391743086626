import Colors from "../../../theme/colors/colors";

export default {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 100
    },
    logo: {
        height: 90
    },
    sloganImage: {
        width: '50%'
    },
    title: {

        fontFamily: "BebasNeue",
        fontSize: 28,
        color: Colors.white,
        margin: '20px 0px'
    }
}
