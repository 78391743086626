import React from "react";

import Input from "../../units/Input/Input";

import styles from "./styles";
import {STRING} from "../../../constants/strings/strings";
import Button from "../../units/Button/Button";

const PlayerRegister = ({onFormSubmitted, onInputChange, inputValue, loading = false}) => {

    return (
        <form style={styles.formWrapper} onSubmit={onFormSubmitted}>
            <Input
                name="name"
                placeholder={STRING.NAME}
                onChange={onInputChange}
                value={inputValue?.name}
                type={"text"}
                customStyle={styles.input}
            />
            <Input
                name="hospital"
                placeholder={STRING.HOSPITAL}
                onChange={onInputChange}
                value={inputValue?.hospital}
                type={"text"}
                customStyle={styles.input}
            />
            <div style={styles.buttonWrapper}>
                <Button title={STRING.LETS_GO} disable={!inputValue?.name || !inputValue?.hospital} loading={loading}/>
            </div>
        </form>
    )
};

export default PlayerRegister;
