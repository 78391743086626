import React from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";

const CookiesPolicy = () => {
    return (
        <Body>
            <MainBackground>
                <p><strong>POLITICA DE COOKIES</strong></p>
                <p>&nbsp;</p>
                <p><strong>&iquest;Qu&eacute; son las Cookies?</strong></p>
                <p>Las cookies son archivos de texto que se instalan en el equipo desde el que se accede a esta p&aacute;gina y que env&iacute;an, de forma autom&aacute;tica, informaci&oacute;n a nuestro servidor.&nbsp;Las cookies no son virus inform&aacute;ticos. No da&ntilde;an su ordenador ni ralentizan su funcionamiento. Puede eliminarlas en cualquier momento, o rechazarlas configurando el programa de navegaci&oacute;n que utiliza.&nbsp;</p>
                <p><strong>&iquest;Por qu&eacute; son importantes?</strong></p>
                <p>Las cookies nos ayudan a sacar lo mejor de nuestra Web y a proporcionarle un servicio m&aacute;s personalizado. Desde un punto de vista t&eacute;cnico, permiten que las p&aacute;ginas Web funcionen de forma m&aacute;s &aacute;gil y adaptada a las preferencias de los usuarios. Adem&aacute;s, nos ayudan a mejorar los contenidos y servicios que se ofrecen, gracias a la informaci&oacute;n estad&iacute;stica que recoge a trav&eacute;s de ella.</p>
                <p>&nbsp;<strong>&iquest;Qu&eacute; tipo de&nbsp;<em>cookies</em>&nbsp;existen?</strong></p>
                <p><strong>Seg&uacute;n su finalidad:</strong></p>
                <ul>
                    <li><strong>Cookies t&eacute;cnicas</strong><strong>:</strong>&nbsp;son aquellas que permiten al Usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina Web, plataforma o aplicaci&oacute;n y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, realizar la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de videos o sonido o compartir contenidos a trav&eacute;s de redes sociales.</li>
                    <li><strong>Cookies de personalizaci&oacute;n:</strong>&nbsp;son aquellas que permiten al Usuario acceder al servicio con algunas caracter&iacute;sticas de car&aacute;cter general predefinidas en funci&oacute;n de una serie de criterios en el terminal del Usuario, como por ejemplo: el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, etc.</li>
                    <li><strong>Cookies de&nbsp;an&aacute;lisis:</strong>&nbsp;son aquellas &nbsp;que &nbsp;nos&nbsp;&nbsp; permiten &nbsp;el&nbsp; &nbsp;seguimiento &nbsp;y&nbsp; &nbsp;an&aacute;lisis &nbsp;del comportamiento de los Usuarios del sitio Web. La informaci&oacute;n recogida mediante este tipo de cookies se utiliza en la medici&oacute;n de la actividad del sitio Web y para la elaboraci&oacute;n de perfiles de navegaci&oacute;n de los Usuarios con el fin de introducir mejoras en funci&oacute;n del an&aacute;lisis de los datos de uso que hacen los Usuarios del servicio.</li>
                    <li><strong>Cookies publicitarias:</strong>&nbsp;son aquellas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, se incluyan en la p&aacute;gina Web en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.</li>
                    <li><strong>Cookies de publicidad comportamental:</strong>&nbsp;son aquellas que permiten la gesti&oacute;n, de la forma m&aacute;s eficaz posible, de los espacios publicitarios que, en su caso, el titular de la p&aacute;gina Web haya incluido en una Web. Estas cookies almacenan informaci&oacute;n del comportamiento de los Usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</li>
                </ul>
                <p><strong>Seg&uacute;n la entidad que las gestiona:</strong></p>
                <ul>
                    <li><strong>Cookies propias:</strong>&nbsp;son aquellas que se env&iacute;an al equipo terminal del Usuario desde un equipo o dominio gestionado por el titular de la p&aacute;gina Web, en este caso, Pulso ediciones, S.L.U. y desde el que se presta el servicio solicitado por el Usuario.</li>
                    <li><strong>Cookies de terceros:</strong>&nbsp;son aquellas que se env&iacute;an al equipo terminal del Usuario desde un equipo o dominio que no es gestionado por el titular de la p&aacute;gina Web, sino por otra entidad que trata los datos obtenidos trav&eacute;s de las cookies.</li>
                </ul>
                <p><strong>Seg&uacute;n el plazo de tiempo que permanecen activadas:</strong></p>
                <ul>
                    <li><strong>Cookies de sesi&oacute;n:</strong>&nbsp;son un tipo de cookies dise&ntilde;adas para recabar y almacenar datos mientras el Usuario accede a la p&aacute;gina Web. Se suelen emplear para almacenar informaci&oacute;n que solo interesa conservar para la prestaci&oacute;n del servicio solicitado por el Usuario en una sola ocasi&oacute;n. La informaci&oacute;n obtenida por medio de estas cookies, sirve para analizar pautas de tr&aacute;fico en la Web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.</li>
                    <li><strong>Cookies persistentes:</strong>&nbsp;son almacenadas en el disco duro y nuestra Web las lee cada vez que el Usuario realiza una nueva visita. Una cookie permanente posee una fecha de expiraci&oacute;n determinada. La cookie dejar&aacute; de funcionar despu&eacute;s de esa fecha.</li>
                </ul>
                <p>&nbsp;<strong>&iquest;Qu&eacute; tipo de cookies utiliza</strong>&nbsp;<a href="https://www.verdadomitoenemd.com">https://www.verdadomitoenemd.com</a>?</p>
                <p>Siguiendo las directrices de la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos procedemos a detallar el uso de&nbsp;<em>cookies</em>&nbsp;que hace esta web con el fin de informarle con la m&aacute;xima exactitud posible.</p>
                <p><strong>Cookies de terceros:</strong></p>
                <ul>
                    <li>Cookies de Google Analytics: para analizar y optimizar la funcionalidad de nuestra Web utilizamos Google Analytics, cuyas principales cookies son: _utma, _utmb, _utmc, _utmz, las cuales proporcionan informaci&oacute;n an&oacute;nima sobre la interacci&oacute;n del usuario en la p&aacute;gina Web.</li>
                </ul>
                <p><strong>Cookies propias:</strong></p>
                <ul>
                    <li>Cookies t&eacute;cnicas: aquellas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de las Web y la utilizaci&oacute;n de los diferentes servicios u opciones que se ofrecen.</li>
                </ul>
                <p>&nbsp;<strong>&iquest;Puedo configurar la instalaci&oacute;n de cookies en mi navegador?</strong></p>
                <p>El usuario puede configurar su navegador para ser avisado de la recepci&oacute;n de cookies y, si lo desea, impedir su instalaci&oacute;n en su equipo. Asimismo, el usuario puede revisar en su navegador qu&eacute; cookies tiene instaladas y cu&aacute;l es el plazo de caducidad de las mismas, pudiendo eliminarlas. Si desea impedir el uso de cookies, le ofrecemos la siguiente informaci&oacute;n, relativa a los principales navegadores:</p>
                <p><strong>Chrome</strong>:&nbsp; <a href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></p>
                <p><strong>Explorer:</strong> <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a></p>
                <p><strong>Firefox</strong>:&nbsp;&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></p>
                <p><strong>Safari:</strong>&nbsp;<a href="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</a></p>
                <p><strong>Safari para IOS:</strong>&nbsp;<a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES&amp;locale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES&amp;locale=es_ES</a></p>
                <p><strong>Opera</strong>:&nbsp;<a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
                <p>En caso de que el usuario no permita el uso de cookies durante su navegaci&oacute;n por este sitio web&nbsp;<a href="https://www.verdadomitoenemd.com">https://www.verdadomitoenemd.com</a>&nbsp;no garantiza que la informaci&oacute;n aparecida durante la navegaci&oacute;n por el mismo sea exacta, completa o, incluso, que la navegaci&oacute;n sea t&eacute;cnicamente posible o viable.</p>
                <p><strong>Contacto</strong></p>
                <p>Si desea m&aacute;s informaci&oacute;n sobre nuestra pol&iacute;tica de cookies se puede dirigir a:&nbsp;<a href="mailto:comunicacion@pulso.com">comunicacion@pulso.com</a></p>
            </MainBackground>
        </Body>
    )
}

export default CookiesPolicy;
