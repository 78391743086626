import axiosService from "./axiosService";

export const loginMedico = (sessionId) => {
    const url = "/public/auth?login_type=medico";
    return axiosService.post(url, {sesion_id: sessionId})
};

export const registerMedicoApi = (nombre, hospital, sessionId, token = null) => {
    const url = "/collections/save/medicos";
    return axiosService.post(url, {data: {nombre, hospital, sesion: {_id: sessionId}}}, !!token ? {headers: {"Cockpit-Token": token}} : {})
};


export const postAnswerMedicoApi = (sessionId, affirmationId, medicoId, respuesta, token = null) => {
    const url = "/collections/save/respuestas_medicos";
    return axiosService.post(url,
        {data: {
                sesion: {_id: sessionId},
                afirmacion: {_id: affirmationId},
                medico: {_id: medicoId},
                respuesta_usuario: respuesta
            }},
        !!token ? {headers: {"Cockpit-Token": token}} : {}
    )
};
