import React from "react";

import "./styles.css";
import styles from "./styles";

const Input = ({
                   type = "text",
                   placeholder = "",
                   value = "",
                   name = "",
                   onChange = () => {},
                   customStyle = {}
               }) => {
    return (
        <input
            className="input"
            style={{...styles.input, ...customStyle}}
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            autoComplete={"off"}
        />
    )
};

export default Input;
