import Colors from "../../../theme/colors/colors";

export default {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 100,
        justifyContent: 'space-between'
    },
    logo: {
        height: 130
    },
    title: {
        fontFamily: "BebasNeue",
        fontSize: 28,
        color: Colors.white,
    },
    middleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%'
    }
};
