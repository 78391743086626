import React, {useEffect, useState} from "react";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import {useNavigate, useParams} from "react-router-dom";
import {getSessionService} from "../../../data/service/informesService";
import {
    Box, Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField, Tooltip
} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import {PictureAsPdf, RemoveRedEye, Summarize} from "@mui/icons-material";
import CustomButton from "../../../components/units/Button/Button";
import InformeReunion from "../../../components/layout/informes/informeReunion/informeReunion";
import { PDFDownloadLink } from '@react-pdf/renderer';
import {generateMedicoString} from "../../../constants/room";
import InformeReunionExcelDownload from "../../../components/layout/informes/informeReunion/informeReunionExcel";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import Loader from "../../../components/units/loader/Loader";
import Colors from "../../../theme/colors/colors";

const headCells = [
    {
        id: 'num_afirmacion',
        numeric: false,
        disablePadding: false,
        label: 'Nº Afirmación',
    },
    {
        id: 'titulo',
        numeric: false,
        disablePadding: false,
        label: 'Título',
    },
    {
        id: 'respuestas_totales',
        numeric: false,
        disablePadding: false,
        label: 'Respuestas totales',
    },
    {
        id: 'aciertos',
        numeric: false,
        disablePadding: false,
        label: 'Aciertos',
    },
    {
        id: 'fallos',
        numeric: true,
        disablePadding: false,
        label: 'Fallos',
    },
    {
        id: 'resp_correctas',
        numeric: true,
        disablePadding: false,
        label: 'Nº correctas',
    },
    {
        id: 'resp_incorrectas',
        numeric: false,
        disablePadding: false,
        label: 'Nº incorrectas',
    },
    {
        id: 'accion',
        numeric: false,
        disablePadding: false,
        label: 'Acción',
    },
];

const headCellsVisita = [
    {
        id: 'num_afirmacion',
        numeric: false,
        disablePadding: false,
        label: 'Nº Afirmación',
    },
    {
        id: 'titulo',
        numeric: false,
        disablePadding: false,
        label: 'Título',
    },
    {
        id: 'correcta',
        numeric: false,
        disablePadding: false,
        label: 'Respuesta',
    }
];

const ReportDownload = () => {

    const navigate = useNavigate();

    const params = useParams();

    const sessionId = params.sessionId;
    const format = params.format;

    const [fetching, setFetching] = useState(true);
    const [downloading, setDownloading] = useState(true);

    const [isVisita, setIsVisita] = useState(false);

    const [session, setSession] = useState([]);


    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('fecha');

    const [search, setSearch] = useState("");

    const [modal, setModal] = useState(false);
    const [afirmacion, setAfirmacion] = useState({});


    useEffect(() => {
        fetchData()

    }, [sessionId])

    const fetchData = () => {
        setFetching(true);
        getSessionService(sessionId)
            .then(r => {
                console.log("data fetched", r);
                let respuestasCorrectasTotales = 0;
                let respuestasIncorrectasTotales = 0;
                r.afirmaciones_realizadas?.forEach(afirmacion => {
                    respuestasCorrectasTotales += afirmacion.respuestas_correctas;
                    respuestasIncorrectasTotales += afirmacion.respuestas_incorrectas;
                })
                r.respuestasCorrectasTotales = respuestasCorrectasTotales;
                r.respuestasIncorrectasTotales = respuestasIncorrectasTotales;
                r.respuestasTotales = respuestasCorrectasTotales + respuestasIncorrectasTotales;
                r.porcentajeCorrectas = 0;
                if (r.respuestasTotales) {
                    r.porcentajeCorrectas = (r.respuestasCorrectasTotales / r.respuestasTotales * 100).toFixed(2)
                }
                r.respuestasCorrectas = r.respuestas?.filter((el) => el.correcta === true)
                r.respuestasIncorrectas = r.respuestas?.filter((el) => el.correcta === false)
                setIsVisita(r.medicos?.length === 1)
                setSession(r)
                setFetching(false);
                setDownloading(true);
                setTimeout(() => simulateMouseClick('button'), 2000);
                setTimeout(() => setDownloading(false), 1950);
            })
            .catch(err => {
                console.error(err)
                setFetching(false);
            })
    }

    const mouseClickEvents = ['click'];
    function simulateMouseClick(selector){
        var element = document.querySelector(selector);
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
        window.opener = null;
        window.open("", "_self");
        setTimeout(() => window.close(), 500);
    }


    if (fetching) {
        return <div className={"downloadFile"}><Loader color={'green'}/></div>
    }

    else {
        return (
            <>
                {downloading &&   <div className={"downloadFile"}><Loader color={'green'}/></div>}
                {format === "pdf" &&
                    <div className={"downloadFile"} style={{opacity: downloading ? '0' : '1'}}>
                        <PDFDownloadLink document={<InformeReunion delegado={session.delegado?.nombre} fecha={session.fecha}
                                                                   nombreSala={session.nombre} medicosRegistrados={session.medicos}
                                                                   porcAciertosTotales={`${session.porcentajeCorrectas}% aciertos, ${100-session.porcentajeCorrectas}% fallos`}
                                                                   afirmacionesTrabajadas={session.afirmaciones_realizadas}
                                                                   respuestasCorrectas={session.respuestasCorrectas}
                                                                   respuestasIncorrectas={session.respuestasIncorrectas} isVisita={isVisita}
                        />
                        } fileName={session.fecha+"-"+session.nombre+".pdf"}>
                            {({ blob, url, loading, error }) =>
                                loading ? '' : <Tooltip title={"Descargar en PDF"}><Button style={{margin: 0, padding: 0, minWidth: 32}}><PictureAsPdf /></Button></Tooltip>
                            }
                        </PDFDownloadLink>
                        <span style={{marginTop: 20}}>Pulsa el botón superior si no se descarga automáticamente.</span>
                        <span style={{marginTop: 20}}>Puedes cerrar esta pestaña cuando el archivo se haya descargado.</span>
                    </div>
                }
                {format === "excel" &&
                    <div className={"downloadFile"} style={{opacity: downloading ? '0' : '1'}}>
                        <InformeReunionExcelDownload delegado={session.delegado?.nombre} fecha={session.fecha}
                                                     nombreSala={session.nombre} medicosRegistrados={session.medicos}
                                                     porcAciertosTotales={`${session.porcentajeCorrectas}% aciertos, ${100-session.porcentajeCorrectas}% fallos`}
                                                     afirmacionesTrabajadas={session.afirmaciones_realizadas}
                                                     respuestasCorrectas={session.respuestasCorrectas}
                                                     respuestasIncorrectas={session.respuestasIncorrectas} isVisita={isVisita} />
                        <span style={{marginTop: 20}}>Pulsa el botón superior si no se descarga automáticamente</span>
                        <span style={{marginTop: 20}}>Puedes cerrar esta pestaña cuando el archivo se haya descargado.</span>

                    </div>
                }
            </>
        )
    }
};

export default ReportDownload;
