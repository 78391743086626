import React from "react";

import "./styles.css";

const AffirmationCard = ({ content, onClick }) => {
    const {title, subtitle, completed} = content;

    return (
        <div className={completed ? "container completed" : "container"} onClick={() => onClick()}>
            <p className="container__title">{title}</p>
            <p className="container__text">{subtitle}</p>
        </div>
    )
};

export default AffirmationCard;
