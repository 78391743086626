import React, {useEffect, useState} from "react";

import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import AffirmationCard from "../../../components/units/AffirmationCard/AffirmationCard";

import styles from "./styles";
import {getAfirmacionesInSessionService} from "../../../data/service/delegadoService";
import {useNavigate, useParams} from "react-router-dom";

const Affirmations = () => {

    const {meetingType, roomCode} = useParams();

    const navigate = useNavigate();

    const [fetching, setFetching] = useState(true);

    const [afirmaciones, setAfirmaciones] = useState([]);

    let polling = null;

    useEffect(() => {
        fetchData()
        if (!polling) polling = setInterval(fetchData, 4000);

        return function cleanup() {
            clearInterval(polling)
        }
    }, [])

    const handleClickAffirmation = (affirmationId) => {
        navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/video`);
    }

    const fetchData = () => {
        setFetching(true);
        getAfirmacionesInSessionService(roomCode)
            .then(r => {
                console.log(r);
                setAfirmaciones(r)
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    return (
        <Body>
            <MainBackground roomCode={roomCode} title={STRING.AFFIRMATIONS} loading={fetching && Object.keys(afirmaciones).length === 0}>
                <div style={styles.mainContainer}>
                    {Object.keys(afirmaciones).map((category, index) =>
                        <>
                            <h2 style={{marginBottom: 8}}>{afirmaciones[category]?.name}</h2>
                            <div style={styles.container}>
                                {afirmaciones[category]?.affirmations?.map((item, index) =>
                                    <AffirmationCard key={index} content={item} onClick={() => handleClickAffirmation(item._id)}/>)}
                            </div>
                        </>
                    )}
                </div>
            </MainBackground>
        </Body>
    )
};

export default Affirmations;
