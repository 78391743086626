import Colors from "../../../theme/colors/colors";

export default {
    container: {
        display: 'flex',
        alignItems: 'center',
        height: 18
    },
    checkbox: {
        width: 18,
        height: '100%',
        borderRadius: 4,
        cursor: 'pointer'
    },
    text: {
        color: Colors.white,
        fontWeight: 500,
        marginLeft: 12
    }
}
