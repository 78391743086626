import React from "react";
import Body from "../../components/layout/Body/Body";
import MainBackground from "../../components/layout/MainBackground/MainBackground";

const PrivacyPolicy = () => {
    return (
        <Body>
            <MainBackground>
                <p><strong>INFORMACI&Oacute;N ADICIONAL Y DETALLADA SOBRE PROTECCI&Oacute;N DE DATOS</strong></p>
                <p>&nbsp;</p>
                <p><strong>&iquest;Qui&eacute;nes son los encargados del tratamiento de sus datos?</strong></p>
                <p>El encargado de los datos personales que nos facilite es:</p>
                <ul>
                    <li><strong>Pulso Ediciones, S.L.U.</strong> con NIF n&ordm; B-08621476 y domicilio en la Rambla de Celler, 117-119, Sant Cugat del Vall&egrave;s (08172 - Barcelona) e inscrita en el Registro Mercantil de Barcelona con los siguientes datos: Tomo 30097, folio 71 Hoja n&ordm; B 95627 inscripci&oacute;n 11 Tel&eacute;fono +34 93 589 62 64 y direcci&oacute;n de correo electr&oacute;nico <a href="mailto:comunicacion@pulso.com">comunicacion@pulso.com</a> y direcci&oacute;n de correo electr&oacute;nico del contacto del delegado de protecci&oacute;n de datos: <a href="mailto:comunica@evidenze.com">comunica@evidenze.com</a>.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Descripci&oacute;n de la acci&oacute;n y asignaci&oacute;n individualizada de responsabilidades</strong></p>
                <p><strong>Pulso Ediciones, S.L.U.</strong> (en adelante <strong>Pulso</strong>) ha concebido y desarrollado una actividad divulgativa, formativa y promocional consistente en la organizaci&oacute;n y seguimiento de una formaci&oacute;n, as&iacute; como de su evaluaci&oacute;n mediante un programa. Para ello, dispondr&aacute; de un sitio www.verdadomitoenemd.com &iquest;Verdad o Mito? en EMD creado espec&iacute;ficamente para la formaci&oacute;n y evaluaci&oacute;n alojado en los servidores de Pulso, que ser&aacute; gestionado directamente por <strong>Pulso</strong>.</p>
                <p><strong>Pulso</strong> ha creado una plataforma o web: www.verdadomitoenemd.com; mediante la cual los interesados, especialistas en oftalmologia de atenci&oacute;n primaria o especialidad, podr&aacute;n darse de alta y acceder a un programa creado por <strong>Pulso</strong> y patrocinado por <strong>ALLERGAN AN ABBVIE COMPANY.</strong></p>
                <p>La din&aacute;mica de la formaci&oacute;n y evaluaci&oacute;n consiste en lo siguiente:</p>
                <p>La formaci&oacute;n est&aacute; dirigida a profesionales sanitarios del &aacute;mbito de la oftalmolog&iacute;a. Los contenidos tem&aacute;ticos y las evaluaciones se estructuran en x publicaciones de unas afirmaciones cl&iacute;nicas propuestas por el comit&eacute; cient&iacute;fico, con las que retar&aacute;n los conocimientos de los participantes en torno al Edema Macular Diab&eacute;tico (EMD).</p>
                <p>Para poder acceder al contenido formativo, los interesados deber&aacute;n acceder a la web y registrarse en la misma cumplimentando los datos solicitados en el formulario de inscripci&oacute;n. Dichos datos se alojar&aacute;n en el servidor de <strong>Pulso</strong>, quien ser&aacute; encargado de su seguridad, conservaci&oacute;n y custodia.</p>
                <ul>
                    <li><strong>Pulso actuar&aacute; como encargado del tratamiento</strong> y garantizar&aacute; de la seguridad, conservaci&oacute;n y custodia de los datos, desde el momento en que se alojan en sus servidores hasta que sean suprimidos. En particular, <strong>Pulso</strong> se encargar&aacute; de la gesti&oacute;n de los riesgos dimanantes del proceso de registro, alojamiento, verificaci&oacute;n, cotejo, estructuraci&oacute;n, integridad, confidencialidad y disponibilidad. En cualquier caso, <strong>Pulso</strong> constituir&aacute;, mantendr&aacute;, explotar&aacute; y proteger&aacute; el fichero que se genere con el registro de los datos personales por parte de los participantes en la plataforma de formaci&oacute;n de conformidad con todas las leyes y reglamentos aplicables que m&aacute;s adelante se detallan.</li>
                    <li><strong>Pulso actuar&aacute; como encargado </strong>y atender y dar&aacute; cumplimiento al ejercicio de los derechos de los interesados de acceso, rectificaci&oacute;n, supresi&oacute;n, portabilidad, olvido, limitaci&oacute;n y oposici&oacute;n, incluyendo la elaboraci&oacute;n de perfiles y las decisiones automatizadas.</li>
                </ul>
                <p>&nbsp;<strong>Declaraciones</strong></p>
                <ul>
                    <li>En su calidad de encargados del tratamiento de datos, Pulso declara conocer las obligaciones que incumben a las personas f&iacute;sicas y jur&iacute;dicas que llevan a cabo el tratamiento de datos de car&aacute;cter personal relativos a personas identificadas o identificables y, fundamentalmente, de la necesidad de respetar la seguridad y confidencialidad de los datos incluidos en dichos ficheros, todo ello en el marco del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (Reglamento General de Protecci&oacute;n de Datos o RGPD), y de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales (LOPDGDD).</li>
                    <li>Cuando <strong>Pulso</strong> act&uacute;e como encargado del tratamiento y de acuerdo con los considerandos 81 y 82 y el art&iacute;culo 28 y concordantes del Reglamento General de Protecci&oacute;n de Datos, se obliga a utilizar los datos de car&aacute;cter personal objeto de tratamiento a los que acceda o los que recabe en nombre e inter&eacute;s de aquel, &uacute;nica y exclusivamente para la finalidad convenida. Como norma general, las instrucciones que debe cumplir <strong>Pulso</strong> en el tratamiento de datos personales son las que, en buena l&oacute;gica se deducen de la propia naturaleza de los servicios contratados y son necesarias para su cumplimiento.</li>
                    <li><strong>Pulso</strong> tratar&aacute; los datos como informaci&oacute;n confidencial y aplicar&aacute; las medidas t&eacute;cnicas y organizativas adecuadas para protegerlos contra la p&eacute;rdida, la alteraci&oacute;n, la divulgaci&oacute;n, el acceso o el tratamiento accidentales o il&iacute;citos.</li>
                </ul>
                <p>&nbsp;<strong>&iquest;Con qu&eacute; finalidades tratar&aacute; Pulso sus datos personales?</strong></p>
                <ul>
                    <li>Registrarlo como usuario del sitio web, permitirle acceso a la plataforma formativa y posibilitar el desarrollo de la formaci&oacute;n a trav&eacute;s del sitio web. Contestar a sus solicitudes de informaci&oacute;n, atender sus peticiones y responder sus consultas o dudas.&nbsp;&nbsp;</li>
                    <li>Mantener informado, incluso por medios electr&oacute;nicos, acerca de cursos y servicios de <strong>Pulso</strong>, siempre y cuando el usuario haya consentido de forma expresa el tratamiento de sus datos con esta finalidad, marcando la casilla correspondiente en el formulario de recogida de datos.</li>
                </ul>
                <p><strong>&iquest;Cu&aacute;l es la legitimaci&oacute;n para el tratamiento por Pulso de sus datos?</strong></p>
                <p>La base legal para el tratamiento de sus datos por parte de <strong>Pulso</strong> es su <strong>consentimiento</strong> informado, otorgado libremente en el momento del registro en la plataforma, de forma inequ&iacute;voca o expresa, seg&uacute;n proceda, que podr&aacute; revocar en cualquier momento.</p>
                <p>&nbsp;<strong>&iquest;Por cu&aacute;nto tiempo conservar&aacute; Pulso sus datos?</strong></p>
                <p>Los datos personales proporcionados por los interesados a <strong>Pulso</strong> se conservar&aacute;n hasta que finalice la formaci&oacute;n y las gestiones administrativas derivadas de la misma y, durante los plazos legalmente previstos.</p>
                <p>Los datos recabados para contestar a sus solicitudes de informaci&oacute;n, atender sus peticiones y responder sus consultas o dudas dar se suprimir&aacute;n una vez resulta contestada su petici&oacute;n por correo electr&oacute;nico, si no ha generado un nuevo tratamiento.</p>
                <p>Respecto al env&iacute;o de informaci&oacute;n sobre cursos o servicios de <strong>Pulso</strong>, se conservar&aacute;n hasta el momento en que el usuario retire su consentimiento dado para la recepci&oacute;n de dichas comunicaciones.&nbsp;</p>
                <p><strong>&iquest;A qu&eacute; destinatarios comunicar&aacute; Pulso sus datos?</strong></p>
                <p>Sus datos ser&aacute;n comunicados por <strong>Pulso</strong> a:</p>
                <ul>
                    <li><strong>ALLERGAN AN ABBVIE COMPANY</strong> como patrocinador de la formaci&oacute;n a efectos de conocer los profesionales que la han realizado, siempre previo consentimiento expl&iacute;cito del usuario</li>
                    <li>A otras empresas y entidades del grupo Pulso con fines administrativos.</li>
                    <li>A otras empresas y entidades con las que <strong>Pulso</strong> haya suscrito contratos o alcanzado compromisos, convenios, acuerdos o alianzas de car&aacute;cter estrat&eacute;gico, industrial publicitario o comercial y operen en los sectores de la alimentaci&oacute;n, la nutrici&oacute;n, la salud, la cosm&eacute;tica y el cuidado de los animales, o que consistan en la cesi&oacute;n un&iacute;voca o rec&iacute;proca de datos de car&aacute;cter personal, la deduplicaci&oacute;n o segmentaci&oacute;n de datos, su comparaci&oacute;n seguimiento o macheado de actividades y perfiles.</li>
                    <li>A otras compa&ntilde;&iacute;as o entidades con las que <strong>Pulso</strong> haya concertado una o varias operaciones mercantiles distintas de una fusi&oacute;n, escisi&oacute;n cesi&oacute;n global de activos y pasivos, aportaci&oacute;n o transmisi&oacute;n de negocio o rama de actividad, o cualquier otra operaci&oacute;n de reestructuraci&oacute;n societaria de an&aacute;loga naturaleza.</li>
                    <li>Las autoridades y organismos competentes, en la medida necesaria para el cumplimiento de obligaciones legales.</li>
                    <li>A otras, siempre que exista una obligaci&oacute;n legal.</li>
                </ul>
                <p><strong>&nbsp;Transferencias Internacionales de Pulso</strong></p>
                <p><strong>Pulso</strong> no realizar&aacute; transferencias de datos personales fuera de la Uni&oacute;n Europea, o de los pa&iacute;ses y territorios a los que se reconoce un nivel adecuado de protecci&oacute;n, incluyendo a las entidades certificadas en el marco del Escudo de Privacidad UE-EE.UU.</p>
                <p>&nbsp;<strong>&iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos?</strong></p>
                <p>En la medida que resulten aplicables y exigibles, los derechos en relaci&oacute;n con sus datos de car&aacute;cter personal son los indicados a continuaci&oacute;n. Todos estos derechos ser&aacute;n ejercitados por los interesados ante <strong>Pulso</strong>, sin m&aacute;s excepciones, condiciones y limitaciones que las contenidas en la normativa vigente.</p>
                <p>&nbsp;</p>
                <table>
                    <tbody>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho de acceso:</strong></p>
                            <p>&nbsp;</p>
                        </td>
                        <td width="599">
                            <p>Tiene derecho a <strong>acceder</strong> a los datos personales que <strong>Pulso </strong>haya recabado sobre usted y que le conciernan, y a ejercer dicho derecho con facilidad y a intervalos razonables, con el fin de conocer y verificar la licitud del tratamiento.</p>
                            <p>Si es posible, <strong>Pulso</strong> le facilitar&aacute; <u>acceso remoto</u> a un sistema seguro que le permita un acceso directo a sus datos personales. Este derecho no debe afectar negativamente a los derechos y libertades de terceros, incluidos los secretos comerciales o la propiedad intelectual.</p>
                            <p>Si <strong>Pulso</strong> tratase una gran cantidad de informaci&oacute;n que le concierna podr&aacute; solicitarle, antes de facilitar la informaci&oacute;n, que <u>especifique la informaci&oacute;n o actividades de tratamiento</u> a que se refiere su solicitud.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho de rectificaci&oacute;n:</strong></p>
                        </td>
                        <td width="599">
                            <p>Tiene derecho a que se <strong>rectifiquen</strong> sus datos personales inexactos, y podr&aacute; solicitar que se completen los que sean incompletos.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho de cancelaci&oacute;n y olvido:</strong></p>
                            <p>&nbsp;</p>
                        </td>
                        <td width="599">
                            <p>Tiene derecho a que sus datos personales <strong>se supriman </strong>y dejen de tratarse, por decisi&oacute;n propia, si ya no son necesarios para los fines para los que fueron recogidos, o si son tratados de otro modo, especialmente si se opone a un determinado tratamiento.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho a la portabilidad:</strong></p>
                            <p>&nbsp;</p>
                        </td>
                        <td width="599">
                            <p>&nbsp;Tiene <strong>derecho a recibirlos en un formato estructurado, de uso com&uacute;n, de lectura mec&aacute;nica e interoperable</strong>. Este derecho resulta exigible cuando usted haya facilitado los datos personales prestando su consentimiento, o cuando el tratamiento sea necesario para la ejecuci&oacute;n de un contrato, pero no cuando el tratamiento tenga una base jur&iacute;dica distinta del consentimiento o la contractual.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho de oposici&oacute;n al tratamiento o revocaci&oacute;n del consentimiento:</strong></p>
                        </td>
                        <td width="599">
                            <p>Tiene derecho a <strong>revocar el consentimiento prestado</strong> para el tratamiento de sus datos personales. En determinadas circunstancias, tendr&aacute; derecho a <strong>oponerse total o parcialmente al tratamiento</strong> de cualquier dato personal relativo a su situaci&oacute;n particular.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho de limitaci&oacute;n:</strong></p>
                            <p>&nbsp;</p>
                        </td>
                        <td width="599">
                            <p>En determinadas circunstancias, podr&aacute; solicitar la <strong>limitaci&oacute;n</strong> del tratamiento de sus datos, en cuyo caso &uacute;nicamente los conservaremos para el ejercicio o la defensa de reclamaciones. El derecho se puede utilizar como una alternativa para exigir que se borren datos, o para solicitar que los datos se &ldquo;congelen&rdquo; o se &ldquo;mantengan en el limbo&rdquo; mientras se resuelven otras incidencias.</p>
                        </td>
                    </tr>
                    <tr>
                        <td width="104">
                            <p><strong>Derecho a no ser objeto de una decisi&oacute;n automatizada:</strong></p>
                        </td>
                        <td width="599">
                            <p>Tiene derecho a <strong>no ser objeto de una decisi&oacute;n</strong> que eval&uacute;e aspectos personales relativos a usted, <u>que se base &uacute;nicamente en el tratamiento automatizado</u> y le produzca efectos jur&iacute;dicos o le afecte significativamente de modo similar.</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p>Puede ejercitar en cualquier momento sus derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, oposici&oacute;n al tratamiento, incluida la oposici&oacute;n a la recepci&oacute;n de comunicaciones electr&oacute;nicas, portabilidad, derecho al olvido y limitaci&oacute;n identific&aacute;ndose debidamente y dirigi&eacute;ndose, por v&iacute;a postal, telef&oacute;nica o preferiblemente electr&oacute;nica, a las direcciones indicadas en el apartado &ldquo;<strong>Encargados del tratamiento</strong>&rdquo;. Recuerde que podremos utilizar las medidas razonables para <strong>verificar su identidad</strong>, en particular en el contexto de los servicios en l&iacute;nea y los identificadores en l&iacute;nea.</p>
                <p>Tambi&eacute;n tendr&aacute; derecho a presentar una reclamaci&oacute;n ante la autoridad de protecci&oacute;n de datos competente (la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos) si considera vulnerados sus derechos.</p>
                <p>&nbsp;</p>
                <p><strong>Pulso Ediciones, S.L.U.</strong></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </MainBackground>
        </Body>
    )
}

export default PrivacyPolicy;
