import {
    createNewRoomApi,
    getAfirmacionesApi,
    getMedicosInSessionApi, getResponsesSessionAffirmationApi, getSessionStatusApi,
    loginDelegadoApi,
    registerDelegadoApi, updateSessionAffirmationApi
} from "../api/delegado-api";
import {afirmacionesFormatterFromApi, getAfirmacionFormatter} from "../formatter/afirmacionesFormatter";

export const registerDelegadoService = (nombre, email, password, aceptaTerminos) => {
    return new Promise((resolve, reject) => {
        registerDelegadoApi(nombre, email, password, aceptaTerminos)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve({data: responseData});
                } else throw new Error("Error de registro");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error en el registro. Asegúrese que no se ha registrado ya. Contacte con su responsable si el error persiste.");
            });
    })
};


export const loginDelegadoService = (email, password) => {
    return new Promise((resolve, reject) => {
        loginDelegadoApi(email, password)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error de login");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error en el login. Asegúrese que los datos son correctos. Contacte con su responsable si el error persiste.");
            });
    })
};


export const createNewRoomService = (nombre, delegadoId) => {
    return new Promise((resolve, reject) => {
        createNewRoomApi(nombre, delegadoId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error creando la sala");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error creando la sala. Contacte con su responsable si el error persiste.");
            });
    })
};

export const getMedicosInSessionService = (sessionId) => {
    return new Promise((resolve, reject) => {
        getMedicosInSessionApi(sessionId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries);
                } else throw new Error("Error obteniendo jugadores");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo jugadores. Contacte con su responsable si el error persiste.");
            });
    })
};


export const getAfirmacionesInSessionService = (sessionId) => {
    const afirmaciones = new Promise((resolve, reject) => {
        getAfirmacionesApi()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries);
                } else throw new Error("Error obteniendo afirmaciones");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo afirmaciones. Contacte con su responsable si el error persiste.");
            });
    })

    const sessionStatus = new Promise((resolve, reject) => {
        getSessionStatusApi(sessionId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries);
                } else throw new Error("Error obteniendo estado de sesión");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo estado de sesión. Contacte con su responsable si el error persiste.");
            });
    })
    return Promise.all([afirmaciones, sessionStatus])
        .then(responses => {
            return afirmacionesFormatterFromApi(responses[0], responses[1][0])
        })
        .catch(err => {
            throw new Error("Error obteniendo datos de afirmaciones y sesión");
        })
};

export const getAfirmacionService = (affirmationId, sessionId) => {

    const sessionStatus = new Promise((resolve, reject) => {
        getSessionStatusApi(sessionId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries);
                } else throw new Error("Error obteniendo estado de sesión");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo estado de sesión. Contacte con su responsable si el error persiste.");
            });
    })

    const affirmation = new Promise((resolve, reject) => {
        getAfirmacionesApi(affirmationId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries[0]);
                } else throw new Error("Error obteniendo afirmacion");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo afirmacion. Contacte con su responsable si el error persiste.");
            });
    })

    return Promise.all([affirmation, sessionStatus])
        .then(responses => {
            return getAfirmacionFormatter(responses[0], responses[1][0])
        })
        .catch(err => {
            throw new Error("Error obteniendo datos de afirmaciones y sesión");
        })
}


export const updateSessionAffirmationService = (sessionId, affirmationId) => {
    return new Promise((resolve, reject) => {
        updateSessionAffirmationApi(sessionId, affirmationId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData);
                } else throw new Error("Error actualizando sesión");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error actualizando sesión. Contacte con su responsable si el error persiste.");
            });
    })
}

export const getResponsesSessionAffirmationService = (sessionId, affirmationId) => {
    return new Promise((resolve, reject) => {
        getResponsesSessionAffirmationApi(sessionId, affirmationId)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData?.entries);
                } else throw new Error("Error obteniendo resultados");
            })
            .catch(err => {
                console.error(err.response)
                if (err.response) {
                    reject(err.response.data?.error);
                }
                reject("Error obteniendo resultados. Contacte con su responsable si el error persiste.");
            });
    })
};
