import React, {useEffect, useState} from "react";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import {useNavigate, useParams} from "react-router-dom";
import {getSessionService} from "../../../data/service/informesService";
import {
    Box, Button,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField, Tooltip
} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import {PictureAsPdf, RemoveRedEye, Summarize} from "@mui/icons-material";
import CustomButton from "../../../components/units/Button/Button";
import InformeReunion from "../../../components/layout/informes/informeReunion/informeReunion";
import { PDFDownloadLink } from '@react-pdf/renderer';
import {generateMedicoString} from "../../../constants/room";
import InformeReunionExcelDownload from "../../../components/layout/informes/informeReunion/informeReunionExcel";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";

const headCells = [
    {
        id: 'num_afirmacion',
        numeric: false,
        disablePadding: false,
        label: 'Nº Afirmación',
    },
    {
        id: 'titulo',
        numeric: false,
        disablePadding: false,
        label: 'Título',
    },
    {
        id: 'respuestas_totales',
        numeric: false,
        disablePadding: false,
        label: 'Respuestas totales',
    },
    {
        id: 'aciertos',
        numeric: false,
        disablePadding: false,
        label: 'Aciertos',
    },
    {
        id: 'fallos',
        numeric: true,
        disablePadding: false,
        label: 'Fallos',
    },
    {
        id: 'resp_correctas',
        numeric: true,
        disablePadding: false,
        label: 'Nº correctas',
    },
    {
        id: 'resp_incorrectas',
        numeric: false,
        disablePadding: false,
        label: 'Nº incorrectas',
    },
    {
        id: 'accion',
        numeric: false,
        disablePadding: false,
        label: 'Acción',
    },
];

const headCellsVisita = [
    {
        id: 'num_afirmacion',
        numeric: false,
        disablePadding: false,
        label: 'Nº Afirmación',
    },
    {
        id: 'titulo',
        numeric: false,
        disablePadding: false,
        label: 'Título',
    },
    {
        id: 'correcta',
        numeric: false,
        disablePadding: false,
        label: 'Respuesta',
    }
];

const Report = () => {

    const navigate = useNavigate();

    const params = useParams();

    const sessionId = params.id;

    const [fetching, setFetching] = useState(true);

    const [isVisita, setIsVisita] = useState(false);

    const [session, setSession] = useState([]);


    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('fecha');

    const [search, setSearch] = useState("");

    const [modal, setModal] = useState(false);
    const [afirmacion, setAfirmacion] = useState({});


    useEffect(() => {
        fetchData()

    }, [sessionId])

    const fetchData = () => {
        setFetching(true);
        getSessionService(sessionId)
            .then(r => {
                console.log("data fetched", r);
                let respuestasCorrectasTotales = 0;
                let respuestasIncorrectasTotales = 0;
                r.afirmaciones_realizadas?.forEach(afirmacion => {
                    respuestasCorrectasTotales += afirmacion.respuestas_correctas;
                    respuestasIncorrectasTotales += afirmacion.respuestas_incorrectas;
                })
                r.respuestasCorrectasTotales = respuestasCorrectasTotales;
                r.respuestasIncorrectasTotales = respuestasIncorrectasTotales;
                r.respuestasTotales = respuestasCorrectasTotales + respuestasIncorrectasTotales;
                r.porcentajeCorrectas = 0;
                if (r.respuestasTotales) {
                    r.porcentajeCorrectas = (r.respuestasCorrectasTotales / r.respuestasTotales * 100).toFixed(2)
                }
                r.respuestasCorrectas = r.respuestas?.filter((el) => el.correcta === true)
                r.respuestasIncorrectas = r.respuestas?.filter((el) => el.correcta === false)
                setIsVisita(r.medicos?.length === 1)
                setSession(r)
                setFetching(false);
            })
            .catch(err => {
                console.error(err)
                setFetching(false);
            })
    }



    const handleModalClick = (afirmacion) => {
        setAfirmacion({});
        const afirmacionData = {
            titulo: "Detalle " + afirmacion.titulo_externo.toLowerCase(),
            medicosCorrectos: session.respuestasCorrectas?.length > 0 ? session.respuestasCorrectas.filter((el) => el.afirmacion._id === afirmacion._id).map((respuesta) => {
                return session.medicos.find(medico => medico._id === respuesta.medico._id);
            }) : [],
            medicosIncorrectos: session.respuestasIncorrectas?.length > 0 ? session.respuestasIncorrectas.filter((el) => el.afirmacion._id === afirmacion._id).map((respuesta) => {
                return session.medicos.find(medico => medico._id === respuesta.medico._id);
            }) : [],

        }
        setAfirmacion(afirmacionData);
        setModal(true);
    }

    const renderTableReunion = () => {
        return (
            <TableContainer style={{width: '100%'}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <h2 style={{margin: 0, padding: 0, color: 'black', fontWeight: 'normal', fontSize: '18px'}}>{headCell.label}</h2>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {session?.afirmaciones_realizadas?.map((row, index) =>
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.titulo_externo}
                                </TableCell>
                                <TableCell align="left">{row.pregunta}</TableCell>
                                <TableCell align="left">{row.respuestas_totales}</TableCell>
                                <TableCell align="left">{row.percent_aciertos}</TableCell>
                                <TableCell align="left">{row.percent_fallos}</TableCell>
                                <TableCell align="left">{row.respuestas_correctas}</TableCell>
                                <TableCell align="left">{row.respuestas_incorrectas}</TableCell>
                                <TableCell align="center">
                                    <Button style={{margin: 0, padding: 0, minWidth: 32}} onClick={() => handleModalClick(row)}><RemoveRedEye /></Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderTableVisita = () => {
        return (
            <TableContainer style={{width: '100%'}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headCellsVisita.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <h2 style={{margin: 0, padding: 0, color: 'black', fontWeight: 'normal', fontSize: '18px'}}>{headCell.label}</h2>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {session?.afirmaciones_realizadas?.map((row, index) =>
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.titulo_externo}
                                </TableCell>
                                <TableCell align="left">{row.pregunta}</TableCell>
                                <TableCell align="left">{row.respuestas_correctas === 1 ? "Correcta" : "Incorrecta" }</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    return (
        <Body>
            <MainBackground title={"Detalle: " + session.nombre} loading={fetching && Object.keys(session).length === 0} enableBackButton={true}>
                <div style={{display: "flex", justifyContent: "flex-end", marginBottom: 8, marginTop: -20}}>
                    {!fetching && <PDFDownloadLink document={<InformeReunion delegado={session.delegado?.nombre} fecha={session.fecha}
                                                                             nombreSala={session.nombre} medicosRegistrados={session.medicos}
                                                                             porcAciertosTotales={`${session.porcentajeCorrectas}% aciertos, ${100-session.porcentajeCorrectas}% fallos`}
                                                                             afirmacionesTrabajadas={session.afirmaciones_realizadas}
                                                                             respuestasCorrectas={session.respuestasCorrectas}
                                                                             respuestasIncorrectas={session.respuestasIncorrectas} isVisita={isVisita}
                    />
                    } fileName={session.fecha+"-"+session.nombre+".pdf"}>
                        {({ blob, url, loading, error }) =>
                            loading ? '' : <Tooltip title={"Descargar en PDF"}><Button style={{margin: 0, padding: 0, minWidth: 32}}><PictureAsPdf /></Button></Tooltip>
                        }
                    </PDFDownloadLink>}
                    {!fetching &&
                        <InformeReunionExcelDownload delegado={session.delegado?.nombre} fecha={session.fecha}
                                                     nombreSala={session.nombre} medicosRegistrados={session.medicos}
                                                     porcAciertosTotales={`${session.porcentajeCorrectas}% aciertos, ${100-session.porcentajeCorrectas}% fallos`}
                                                     afirmacionesTrabajadas={session.afirmaciones_realizadas}
                                                     respuestasCorrectas={session.respuestasCorrectas}
                                                     respuestasIncorrectas={session.respuestasIncorrectas} isVisita={isVisita} />
                    }
                </div>



                <Paper style={{padding: 16}}>
                    <div style={{display: "flex", alignItems: "center"}}><h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black"}}>FECHA DE REALIZACIÓN:</h2><span style={{marginLeft: 4}}>{session.fecha}</span></div>
                    <div style={{display: "flex", alignItems: "center"}}><h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black"}}>DELEGADO:</h2><span style={{marginLeft: 4}}>{session.delegado?.nombre}</span></div>
                    <div>
                        <h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black", marginRight: 4, marginBlockEnd: 0}}>
                            {isVisita ? "MÉDICO REGISTRADO" : "MÉDICOS REGISTRADOS"}:</h2>
                        <div style={{display: "flex", alignItems: "center", flexWrap: 'wrap'}}>
                            <span>
                            {session?.medicos?.length > 0 ?
                                session?.medicos?.map((medico, index) => index !== 0 ? ", "+ generateMedicoString(medico) : generateMedicoString(medico))
                                : "Sin resultados"
                            }
                            </span>
                        </div>
                    </div>

                    <h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black", marginBottom: 0}}>AFIRMACIONES TRABAJADAS:</h2>
                    {isVisita ? renderTableVisita() : renderTableReunion()}
                    <h2 style={{margin: 0, padding: 0, color: 'black', fontWeight: 'normal', fontSize: '18px', marginTop: 12}}>Total afirmaciones trabajadas: {session.afirmaciones_realizadas?.length}</h2>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black"}}>% totales:</h2>
                        <span style={{marginLeft: 4}}>{session.porcentajeCorrectas}% aciertos, {100-session.porcentajeCorrectas}% fallos</span>
                    </div>
                </Paper>
            </MainBackground>
            <Modal open={modal} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Paper style={{padding: 32, display: "flex", flexDirection: 'column', minWidth: 300, minHeight: 300}}>
                    <span style={{textAlign: "center", fontSize: 12, paddingBottom: 24}}>{afirmacion.titulo}</span>
                    <div style={{flex: 1}}>
                        <div>
                            <h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black"}}>Respondido correctamente: </h2>
                            {afirmacion?.medicosCorrectos?.length > 0 ?
                                afirmacion?.medicosCorrectos?.map((medico, index) => <span>{index !== 0 && ", "}{generateMedicoString(medico)}</span>)
                                : "Sin resultados"
                            }
                        </div>
                        <div style={{marginTop: 42}}>
                            <h2 style={{fontWeight: 'normal', fontSize: '20px', color: "black"}}>Respondido incorrectamente: </h2>
                            {afirmacion?.medicosIncorrectos?.length > 0 ?
                                afirmacion?.medicosIncorrectos?.map((medico, index) => <span>{index !== 0 && ", "}{generateMedicoString(medico)}</span>)
                                : "Sin resultados"
                            }
                        </div>
                    </div>
                    <CustomButton title={"Cerrar"} onClick={() => {
                        setModal(false)
                        setAfirmacion({});
                    }}/>
                </Paper>
            </Modal>
        </Body>
    )
};

export default Report;
