import React from "react";

import "./loader.css"

const Loader = ({color = "white"}) => {
    return(
        <div className={"lds-dual-ring " + color}/>
    )
}

export default Loader;
