import React, {useEffect, useState} from "react";

import {STRING} from "../../../constants/strings/strings";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import AffirmationCard from "../../../components/units/AffirmationCard/AffirmationCard";

import styles from "./styles";
import {
    getAfirmacionesInSessionService,
    getAfirmacionService,
    getMedicosInSessionService, getResponsesSessionAffirmationService, updateSessionAffirmationService
} from "../../../data/service/delegadoService";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import Button from "../../../components/units/Button/Button";
import VerdadOMitoCard from "../../../components/units/VerdadOMitoCard/VerdadOMitoCard";

const AffirmationResults = () => {

    const {meetingType, roomCode, affirmationId} = useParams();

    const navigate = useNavigate();

    const [fetching, setFetching] = useState(false);

    const [afirmacion, setAfirmacion] = useState(null);
    const [responses, setResponses] = useState([]);
    const [medicos, setMedicos] = useState([]);

    let polling = null;

    useEffect(() => {
        fetchResponses()
        if (!polling) polling = setInterval(fetchResponses, 3000);

        return function cleanup() {
            clearInterval(polling)
        }
    }, [])

    useEffect(async () => {
        await fetchAfirmacion()
        fetchMedicos();
    }, [])

    useEffect(() => {
        if (afirmacion) {
            enableAffirmation(affirmationId);
        }
    }, [afirmacion])


    const fetchAfirmacion = () => {
        setFetching(true);
        getAfirmacionService(affirmationId, roomCode)
            .then(r => {
                console.log(r);
                setAfirmacion(r)
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    const enableAffirmation = (affirmationId = null) => {
        if (afirmacion.completed && affirmationId !== null) return
        updateSessionAffirmationService(roomCode, affirmationId)
            .then(r => {
                console.log(r);
                setFetching(false);
            })
            .catch(err => {
                alert("No se ha podido activar la pregunta. Compruebe la conexión y recargue la página para volver a intentarlo")
                setFetching(false);
            })

    }

    const fetchResponses = () => {
        getResponsesSessionAffirmationService(roomCode, affirmationId)
            .then(r => {
                console.log(r);
                setResponses(r);
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    const fetchMedicos = () => {
        getMedicosInSessionService(roomCode)
            .then(r => {
                console.log(r);
                setMedicos(r);
                setFetching(false);
            })
            .catch(err => {
                setFetching(false);
            })
    }

    const handleNextButton = () => {
        setFetching(true);
        enableAffirmation(null)
        navigate(`/delegado/affirmations/${meetingType}/${roomCode}/${affirmationId}/justification/1`);

    }

    const handleBackButton = () => {
        console.log('clicked back button');
    }

    const verdad = responses.filter((response => response.respuesta_usuario === "Verdad"))
    const mito = responses.filter((response => response.respuesta_usuario === "Mito"))

    return (
        <Body>
            <MainBackground enableBackButton backButtonPress={handleBackButton} roomCode={roomCode} title={afirmacion?.titulo_externo ?  afirmacion?.titulo_externo : "CARGANDO..."} loading={fetching}>
                <p className="background__title-text" style={{textAlign: "center"}}>CONTESTAD LA PREGUNTA EN VUESTRO MÓVIL</p>
                <p className="background__title-text" style={{textAlign: "center", fontFamily: "Barlow", fontSize: 20, padding:8}}>{responses.length} de {medicos.length} respuestas</p>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: 24, flex: 1}}>
                    <div style={{display: "flex", flexDirection: 'row', gap: 60}}>
                        <VerdadOMitoCard text={"VERDAD"} verdad={true} resultText={`${verdad.length} de ${medicos.length}`}/>
                        <VerdadOMitoCard text={"MITO"} resultText={`${mito.length} de ${medicos.length}`}/>
                    </div>
                </div>


                <div style={{display: "flex", justifyContent: "center", padding: 12}}>
                    <div style={{maxWidth: 200}}>
                        <Button title={STRING.CONTINUE} onClick={handleNextButton}/>
                    </div>
                </div>
            </MainBackground>
        </Body>
    )
};

export default AffirmationResults;
