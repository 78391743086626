import React, {useEffect, useState} from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, PDFViewer } from '@react-pdf/renderer';
import InformeReunionBg from "../../../../assets/Informe-reunion-bg.jpg"
import InformeVisitaBg from "../../../../assets/Informe-visita-bg.jpg"
import axiosService from "../../../../data/api/axiosService";
import {generateMedicoString} from "../../../../constants/room";

const styles = StyleSheet.create({
    pageBackground: {
        position: 'fixed',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
        top:0,
        bottom: 0,
    },
    page: {
        flexDirection: 'column',
        paddingTop: 114,
        paddingBottom: 80,
    },
    pageContainer: {
        padding: 18,
    },
    section: {
        margin: 0,
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        width: '100%',
        height: 842,
        overflow: 'visible'
    },
    title: {
        fontFamily: "BebasNeue",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        paddingTop: 6,
        paddingBottom: 6
    },
    text: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
    },
    pageNumber: {
        fontFamily: "Barlow",
        fontStyle: "normal",
        position: 'absolute',
        fontSize: 10,
        bottom: 16,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'white',
    },
});

Font.register({ family: 'BebasNeue', src: "/fonts/bebasneue-regular.ttf" });
Font.register({ family: 'Barlow', src: "/fonts/barlow-regular.ttf" });

const generateAuxData = (afirmacion, respuestasCorrectas, respuestasIncorrectas, medicos) => {
    return {
        medicosCorrectos: respuestasCorrectas?.length > 0 ? respuestasCorrectas.filter((el) => el.afirmacion._id === afirmacion._id).map((respuesta) => {
            return medicos.find(medico => medico._id === respuesta.medico._id);
        }) : [],
        medicosIncorrectos: respuestasIncorrectas?.length > 0 ? respuestasIncorrectas.filter((el) => el.afirmacion._id === afirmacion._id).map((respuesta) => {
            return medicos.find(medico => medico._id === respuesta.medico._id);
        }) : [],

    }
}

const InformeReunionAfirmacion = (afirmacion, respuestasCorrectas, respuestasIncorrectas, medicos, isVisita) => {

    const medicosCorrectos = generateAuxData(afirmacion, respuestasCorrectas, respuestasIncorrectas, medicos).medicosCorrectos;
    const medicosIncorrectos = generateAuxData(afirmacion, respuestasCorrectas, respuestasIncorrectas, medicos).medicosIncorrectos;

    if (!isVisita) return (
        <>
            <Text style={{...styles.text, marginTop: 18}}><span style={{textDecoration: "underline"}}>{afirmacion.titulo_externo}:</span> {afirmacion.pregunta}</Text>
            <Text style={{ ...styles.text, marginHorizontal: 8 }}>• Número de respuestas: {afirmacion.respuestas_totales}</Text>
            <Text style={{ ...styles.text, marginHorizontal: 8 }}>• Resp. correctas ({afirmacion.respuestas_correctas + ", " + afirmacion.percent_aciertos}): {
                medicosCorrectos?.length > 0 ?
                    medicosCorrectos?.map((medico, index) => <span>{index !== 0 && ", "}{generateMedicoString(medico)}</span>)
                    : "Sin resultados"
            }</Text>
            <Text style={{ ...styles.text, marginHorizontal: 8 }}>• Resp. incorrectas ({afirmacion.respuestas_incorrectas + ", " + afirmacion.percent_fallos}): {
                medicosIncorrectos?.length > 0 ?
                    medicosIncorrectos?.map((medico, index) => <span>{index !== 0 && ", "}{generateMedicoString(medico)}</span>)
                    : "Sin resultados"
            }</Text>
        </>
    )
    return (
        <>
            <Text style={{...styles.text, marginTop: 18}}><span style={{textDecoration: "underline"}}>{afirmacion.titulo_externo}:</span> {afirmacion.pregunta}</Text>
            <Text style={{ ...styles.text, marginHorizontal: 8 }}>• Respuesta: {afirmacion.respuestas_correctas === 1 ? "Correcta" : "Incorrecta"}</Text>
        </>
    )
}


const InformeReunion = ({
    delegado,
    nombreSala,
    fecha,
    medicosRegistrados,
    afirmacionesTrabajadas,
    porcAciertosTotales,
    respuestasCorrectas,
    respuestasIncorrectas,
    isVisita
                        }) => (

    <Document>
        <Page size="A4" style={styles.page}>
            <View fixed={true} style={styles.section}>
                <Image src={isVisita ? InformeVisitaBg : InformeReunionBg } style={styles.pageBackground} />
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `Página ${pageNumber} de ${totalPages}`
                )} fixed />
            </View>

                <div style={styles.pageContainer}>
                    <Text style={{...styles.title, paddingTop: 0}}>
                        Delegado: <Text style={styles.text}>{delegado}</Text>
                    </Text>
                    {!isVisita && <Text style={{...styles.title}}>
                        Nombre de la sala: <Text style={styles.text}>{nombreSala}</Text>
                    </Text>}
                    <Text style={{...styles.title}}>
                        Fecha de realización: <Text style={styles.text}>{fecha}</Text>
                    </Text>
                    <Text style={{...styles.title, paddingBottom: 0}}>
                        {isVisita ? "MÉDICO REGISTRADO" : "MÉDICOS REGISTRADOS"}:
                    </Text>
                    <Text style={{...styles.text}}>
                        {medicosRegistrados?.length > 0 ?
                            medicosRegistrados?.map((medico, index) => <span>{index !== 0 && ", "}{generateMedicoString(medico)}</span>)
                            : "Sin resultados"
                        }
                    </Text>
                    <Text style={{...styles.title, marginTop: 6, paddingBottom: 0}}>
                        Afirmaciones trabajadas: <Text style={styles.text}>{afirmacionesTrabajadas?.length}</Text>
                    </Text>
                    <Text style={{...styles.text}}>{porcAciertosTotales}</Text>

                    {afirmacionesTrabajadas?.map(afirmacion => {
                        return InformeReunionAfirmacion(afirmacion, respuestasCorrectas, respuestasIncorrectas, medicosRegistrados, isVisita)
                    })}
                </div>

        </Page>
    </Document>
);


export default InformeReunion
