import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';

import Landing from "./screens/delegate/Landing/Landing";
import Register from "./screens/delegate/Register/Register";
import Login from "./screens/delegate/Login/Login";
import DynamicSelector from "./screens/delegate/DynamicSelector/DynamicSelector";
import RoomCreator from "./screens/delegate/RoomCreator/RoomCreator";
import WaitingRoom from "./screens/delegate/WaitingRoom/WaitingRoom";
import Affirmations from "./screens/delegate/Affirmations/Affirmations";
import LandingPlayer from "./screens/player/LandingPlayer/LandingPlayer";
import RegisterPlayer from "./screens/player/RegisterPlayer/RegisterPlayer";
import PlayerWaitingRoom from "./screens/player/PlayerWaitingRoom/PlayerWaitingRoom";
import Video from "./screens/delegate/Video/Video";
import AffirmationResults from "./screens/delegate/AffirmationResults/AffirmationResults";
import Justification from "./screens/delegate/Justification/Justification";
import AffirmationAnswer from "./screens/player/AffirmationAnswer/AffirmationAnswer";
import AffirmationReveal from "./screens/player/AffirmationReveal/AffirmationReveal";
import RegisterVisitPlayer from "./screens/delegate/RegisterVisitPlayer/RegisterVisitPlayer";
import AffirmationAnswerVisit from "./screens/visit/AffirmationAnswerVisit/AffirmationAnswerVisit";
import AffirmationRevealVisit from "./screens/visit/AffirmationRevealVisit/AffirmationRevealVisit";
import Home from "./screens/Home/Home";
import LegalBases from "./screens/legal/LegalBases";
import LegalWarning from "./screens/legal/LegalWarning";
import CookiesPolicy from "./screens/legal/CookiesPolicy";
import PrivacyPolicy from "./screens/legal/PrivacyPolicy";
import QuestionTitle from "./screens/delegate/QuestionTitle/QuestionTitle";
import AllReports from "./screens/reports/allReports/AllReports";
import Report from "./screens/reports/report/Report";
import ReportDownload from "./screens/reports/reportDownload/ReportDownload";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path={"/"} element={<Home />}/>

                {/*Player*/}
                <Route path={"/medico/:roomCode"} element={<LandingPlayer />}/>
                <Route path={"/medico/meeting/:roomCode/register"} element={<RegisterPlayer />}/>
                <Route path={"/medico/meeting/:roomCode/waiting-room"} element={<PlayerWaitingRoom />}/>
                <Route path={"/medico/meeting/:roomCode/affirmationAnswer/:affirmationId"} element={<AffirmationAnswer />}/>
                <Route path={"/medico/meeting/:roomCode/affirmationReveal/:affirmationId/"} element={<AffirmationReveal />}/>


                {/*delegado*/}
                <Route path={"/delegado"} element={<Landing />}/>
                <Route path={"/delegado/register"} element={<Register />}/>
                <Route path={"/delegado/login"} element={<Login />}/>
                <Route path={"/delegado/selector"} element={<DynamicSelector />}/>
                <Route path={"/delegado/room-creator/:meetingType"} element={<RoomCreator />} />
                <Route path={"/delegado/waiting-room/:meetingType/:roomCode"} element={<WaitingRoom />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode"} element={<Affirmations />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode/:affirmationId/video"} element={<QuestionTitle />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode/:affirmationId/video_final"} element={<Video />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode/:affirmationId/results"} element={<AffirmationResults />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode/:affirmationId/justification/:justificationNum"} element={<Justification />} />
                <Route path={"/delegado/affirmations/:meetingType/:roomCode/:affirmationId/video_conclusion"} element={<Video />} />

                {/*visit*/}
                <Route path={"/delegado/register/visit"} element={<RegisterVisitPlayer />} />
                <Route path={"/delegado/:meetingType/:roomCode/affirmationAnswer/:affirmationId"} element={<AffirmationAnswerVisit />}/>
                <Route path={"/delegado/:meetingType/:roomCode/affirmationReveal/:affirmationId"} element={<AffirmationRevealVisit />}/>


                {/*Legal*/}
                <Route path={"/legal-bases"} element={<LegalBases />} />
                <Route path={"/legal-warning"} element={<LegalWarning />} />
                <Route path={"/cookies-policy"} element={<CookiesPolicy />} />
                <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />

                {/*Informes*/}
                <Route path={"/informes/login"} element={<Login type={'informes'}/>}/>
                <Route path={"/informes/list"} element={<AllReports/>}/>
                <Route path={"/informes/list/:id"} element={<Report/>}/>
                <Route path={"/informes/download/:sessionId/:format"} element={<ReportDownload/>}/>

            </Routes>
        </Router>
    );
}

export default App;
