import React, {useEffect, useState} from "react";

import Body from "../../../components/layout/Body/Body";
import MainBackground from "../../../components/layout/MainBackground/MainBackground";
import {useNavigate} from "react-router-dom";
import {getSessionsService} from "../../../data/service/informesService";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import {PictureAsPdf, RemoveRedEye, Summarize} from "@mui/icons-material";
import moment from "moment";

String.prototype.like = function(search) {
    if (typeof search !== 'string' || this === null) {return false; }
    // Remove special chars
    search = search.replace(new RegExp("([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])", "g"), "\\$1");
    // Replace % and _ with equivalent regex
    search = search.replace(/%/g, '.*').replace(/_/g, '.');
    // Check matches
    return RegExp('^' + search + '$', 'gi').test(this);
}

const headCells = [
    {
        id: 'nombre',
        numeric: false,
        disablePadding: false,
        label: 'Nombre',
    },
    {
        id: 'fecha',
        numeric: false,
        disablePadding: false,
        label: 'Fecha',
    },
    {
        id: 'tipo',
        numeric: false,
        disablePadding: false,
        label: 'Tipo',
    },
    {
        id: 'delegado',
        numeric: false,
        disablePadding: false,
        label: 'Delegado',
    },
    {
        id: 'numParticipantes',
        numeric: true,
        disablePadding: false,
        label: 'Nº participantes',
    },
    {
        id: 'numAfirmaciones',
        numeric: true,
        disablePadding: false,
        label: 'Nº afirmaciones',
    },
    {
        id: 'acciones',
        numeric: true,
        disablePadding: false,
        label: 'Acciones',
    },
];

const AllReports = () => {

    const navigate = useNavigate();

    const [fetching, setFetching] = useState(true);

    const [sessions, setSessions] = useState([]);


    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('fecha');

    const [search, setSearch] = useState("");


    useEffect(() => {
        fetchData()

    }, [])

    const fetchData = () => {
        setFetching(true);
        getSessionsService()
            .then(r => {
                const formattedSessions = r.map(s => {
                    return {
                        _id: s._id,
                        nombre: s.nombre,
                        fecha: s.fecha,
                        delegado: s.delegado?.nombre,
                        tipo: s.nombre === "Visita" ? "Visita" : "Reunión",
                        numParticipantes: s.medicos?.length,
                        numAfirmaciones: s.afirmaciones_realizadas?.length
                    }
                })
                setSessions(formattedSessions)
                setFetching(false);
            })
            .catch(err => {
                console.error(err)
                setFetching(false);
            })
    }

    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (orderBy === "fecha") {
            b = moment(b, "DD-MM-YYYY")
            a = moment(a, "DD-MM-YYYY")
            if (b.isBefore(a)) {
                return 1;
            }
            return -1;
        }
        if (orderBy === "numParticipantes" || orderBy === "numAfirmaciones"){
            b = parseInt(b[orderBy]);
            a = parseInt(a[orderBy]);
            if (isNaN(a)) a = 0;
            if (isNaN(b)) b = 0;
            if (b < a) return -1;
            if (b > a) return 1;
            return 0;
        }
        b = String(b[orderBy]);
        a = String(a[orderBy]);
        if (b < a) return -1;
        if (b > a) return 1;
        return 0;

    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const filterRows = (rows) => {

        if (!search) return rows;

        const res = [];

        rows.forEach(row => {
            const keys = Object.keys(row);
            for (const key of keys) {
                if (key === "_id") continue;
                var el = row[key];
                if (el?.toString()?.like("%"+search+"%")) {
                    res.push(row);
                    el = "";
                    return;
                }
                el = "";
            }
        })

        return res;
    }

    const handleButtonClick = (action, data) => {
        switch (action) {
            case "detail":
                navigate("/informes/list/"+data._id)
                break;
            case "pdf":
                window.open("/informes/download/"+data._id + "/pdf", "_blank");
                break;
            case "excel":
                window.open("/informes/download/"+data._id + "/excel", "_blank");
                break;
            default: break;
        }
    }

    return (
        <Body>
            <MainBackground title={"Reuniones"} loading={fetching && Object.keys(sessions).length === 0}>
                <Box
                    component="div"
                    style={{display: "flex", justifyContent: 'flex-end', marginTop: -40}}
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                <TextField id="outlined-basic" label="Buscar..." variant="outlined"
                            onChange={(e) => setSearch(e.target.value)}
                           style={{backgroundColor: 'white', marginBottom: 12}} placeholder={"Buscar..."} />
                </Box>
                <TableContainer component={Paper} style={{width: '100%'}}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        align={headCell.numeric ? 'center' : 'left'}
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : 'asc'}
                                            onClick={createSortHandler(headCell.id)}
                                        >
                                            <h2 style={{margin: 0, padding: 0, color: 'black', fontWeight: 'normal', fontSize: '18px'}}>{headCell.label}</h2>
                                            {orderBy === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {stableSort(filterRows(sessions), getComparator(order, orderBy))
                                .map((row, index) =>
                                    <TableRow
                                        key={row._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell align="left">{row.fecha}</TableCell>
                                        <TableCell align="left">{row.tipo}</TableCell>
                                        <TableCell align="left">{row.delegado}</TableCell>
                                        <TableCell align="center">{row.numParticipantes}</TableCell>
                                        <TableCell align="center">{row.numAfirmaciones}</TableCell>
                                        <TableCell align="center">
                                            <Button style={{margin: 0, padding: 0, minWidth: 32}} onClick={() => handleButtonClick("detail", {_id: row._id})}><RemoveRedEye /></Button>
                                            <Button style={{margin: 0, padding: 0, minWidth: 32}} onClick={() => handleButtonClick("pdf", {_id: row._id})}><PictureAsPdf /></Button>
                                            <Button style={{margin: 0, padding: 0, minWidth: 32}} onClick={() => handleButtonClick("excel", {_id: row._id})}><Summarize /></Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <h2 style={{margin: 0, padding: 0, color: 'black', fontWeight: 'normal', fontSize: '18px', marginTop: 12}}>Total: {sessions.length}</h2>

            </MainBackground>
        </Body>
    )
};

export default AllReports;
